import React from "react";
import {useTranslation} from "react-i18next";
import {ButtonBase, Card, IconButton, Tooltip, Typography} from "@mui/material";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import {Box} from "@mui/system";

export const DownloadExcelButton = () => {
  const {t} = useTranslation();

  const handleClickDownloadExcelFile = () => {
    const link = document.createElement("a");
    link.href = "/files/GA_Anket_Soru_Taslagi-Sorular.xlsx"; // Path to your Excel file
    link.setAttribute("download", "GA_Anket_Soru_Taslagi-Sorular.xlsx"); // Name of the file when downloaded
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Tooltip title={t("buttons.downloadDraftExcel")} arrow>
      <Card
        component={ButtonBase} // Makes the entire card clickable
        sx={{
          minHeight: 180,
          display: "flex",
          width: "100%",
          alignItems: "center",
          px: 4,
          py: 2,
          my: 4,
          transition: "box-shadow 0.2s", // Adds smooth shadow transition
          "&:hover": {
            boxShadow: 3, // Shadow on hover
          },
          "&:active": {
            boxShadow: 6, // Deeper shadow when clicked
          },
        }}
        onClick={() => {
          handleClickDownloadExcelFile();
          console.log("Card clicked");
          // You can add the logic here for click functionality, like redirecting to another page or action
        }}
      >
        <img src="/assets/svg/galataanketgraylogo.svg" alt="Logo" />

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            ml: 2,
            p: 2,
            textAlign: "left",
          }}
        >
          <Typography variant="h6" component="div" sx={{py: 1}}>
          {t("downloadExcelFormat") } 
          </Typography>
          <Typography variant="body2" color="text.secondary"> 
          {t("downloadDraftExcelDirective")  }
          </Typography>
        </Box>

        <IconButton disabled aria-label="settings">
          <FileDownloadOutlinedIcon fontSize="large" />
        </IconButton>
      </Card>
    </Tooltip>
  );
};
