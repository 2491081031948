import Error404 from "src/pages/404";
import {authRoutes} from "src/routes/auth";
import {mainRoutes} from "src/routes/main";
import {dashboardRoutes} from "src/routes/dashboard";
import {surveyBuilderRoutes} from "src/routes/survey-builder";
import SurveyEngine from "src/pages/new-survey/survey-engine";
import {SurveyEngineLayout} from "src/layouts/survey-engine/survey-engine-layout";
import {actionRoutes} from "src/routes/action";

export const routes = [
  {
    path: "assets/cekilissonucu15102023.pdf",
  },
  {
    path: "assets/cekilissonucu10122023.pdf",
  },
  {
    path: "sonuclar/Instagram Fenomen Anket_kamu_ile_paylas.pdf",
  },
  {
    path: "survey",
    children: [
      {
        path: ":uuid",
        element: (
          <SurveyEngineLayout>
            <SurveyEngine />
          </SurveyEngineLayout>
        ),
      },
    ],
  },
  ...mainRoutes,
  ...authRoutes,
  ...actionRoutes,
  ...dashboardRoutes,
  ...surveyBuilderRoutes,
  {
    path: "*",
    element: <Error404 />,
  },
];
