import {useSurveyEngine} from "src/hooks/use-survey-engine";
import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import {
  AppBar,
  Button,
  Collapse,
  GlobalStyles,
  LinearProgress,
  Paper,
  Slide,
  Typography,
} from "@mui/material";
import {QuestionFactory} from "src/pages/new-survey/question-factory";
import {createTheme, Stack, ThemeProvider} from "@mui/system";
import {LoadingButton} from "@mui/lab";
import {useTranslation} from "react-i18next";
import lodash from "lodash";
import CheckQuestionAnswer from "src/pages/new-survey/check-answers";
import Alert from "@mui/material/Alert";
import {
  displayLogic,
  checkSkipLogic,
} from "src/pages/new-survey/check-question/display-logic";
import EndPage from "src/pages/new-survey/end-page";
import {useTheme} from "@emotion/react";
import {animationTypes} from "../admin/survey-builder/survey-editor/design";
import {createLightOptions} from "src/theme/light";
import {GifSplashScreen} from "src/components/gif-splash-screen";

const checkQuestion = (question, surveyQuestions, surveyAnswers) => {
  if (displayLogic(question, surveyQuestions, surveyAnswers) === false) {
    return false;
  }
  return true;
};

export const Survey = ({maxHeight = "100vh", preview = false}) => {
  const surveyEngine = useSurveyEngine();
  const [isLoading, setIsLoading] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);
  const [skipPageQuestionIndex, setSkipPageQuestionIndex] = useState(null);
  const [pageQuestions, setPageQuestions] = useState([]);
  const [isEnd, setIsEnd] = useState(false);
  const {t} = useTranslation();
  const [pageErrors, setPageErrors] = useState({});
  const [surveyParameters, setSurveyParameters] = useState([]);
  const [showLoadingGif, setShowLoadingGif] = useState(true);
  const isSamsungBrowser = navigator.userAgent.includes("SamsungBrowser");

  const generalTheme = useTheme();

  const [showQuestion, setShowQuestion] = useState(true);
  const [logoSettings] = useState({
    logo: null,
    placement: "Center",
    verticalPlacement: "Top",
    maxHeight: 70,
    maxWidth: 100,
  });
  const [generalSettings] = useState({
    progressBar: "WithText",
    progressBarPlacement: "Center",
    progressBarVerticalPlacement: "Top",
    nextButton: " → ",
    previousButton: " ← ",
  });
  const [styleSettings] = useState({
    fontFamily: generalTheme.typography.fontFamily,
    questionFontSize: 18,
    answerFontSize: 16,
    primaryColor: generalTheme.palette.primary.main,
    secondaryColor: generalTheme.palette.secondary.main,
  });
  const [backgroundSettings] = useState({
    backgroundColor: generalTheme.palette.background.default,
  });
  const [motionSettings] = useState({
    pageTransition: "slide",
    translationDirection: "right",
  });

  const newTheme = createTheme(
    {
      ...generalTheme,
      palette: {
        ...generalTheme.palette,
        primary: {
          main: styleSettings?.primaryColor, // Primary color
        },
        secondary: {
          main: styleSettings?.secondaryColor, // Secondary color
        },
        background: {
          default: backgroundSettings?.backgroundColor, // Custom default background color
        },
      },
      typography: {
        ...generalTheme.typography,
        fontFamily: styleSettings?.fontFamily,
        fontSize: styleSettings?.answerFontSize,
        h6: {
          fontSize: styleSettings?.questionFontSize,
        },
      },
    },
    createLightOptions(),
  );

  const Animation =
    motionSettings?.pageTransition !== "slide" ? (
      animationTypes[motionSettings?.pageTransition]
    ) : (
      <></>
    );

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    const uniqueParameters = {};
    for (const [key, value] of queryParameters.entries()) {
      uniqueParameters[key] = value;
    }
    const nameValueParameters = [];
    Object.keys(uniqueParameters).forEach(key =>
      nameValueParameters.push({name: key, value: uniqueParameters[key]}),
    );
    setSurveyParameters(nameValueParameters);
  }, []);

  const checkQuestionAnswers = pageQuestions => {
    const errors = {};
    !surveyEngine.ignoreRequire &&
      pageQuestions.forEach(question => {
        const checkQuestion = CheckQuestionAnswer(
          question,
          surveyEngine.surveyAnswers[question.id],
        );
        if (checkQuestion !== true) {
          errors[question.id] = checkQuestion;
        }
      });
    setPageErrors(errors);
    return lodash.isEmpty(errors);
  };

  const handleNextButton = _ => {
    setShowQuestion(false);
    setIsLoading(true);
    if (checkQuestionAnswers(pageQuestions)) {
      const pageId = surveyEngine.blockQuestions.results[pageIndex].page_id;
      surveyEngine
        .handlePageAnswers(pageId, pageQuestions.length, surveyParameters)
        .then(_ => {
          let skipPageIndex = null;
          for (const pageQuestion of pageQuestions) {
            let skipLogic = checkSkipLogic(
              pageQuestion,
              surveyEngine.surveyQuestions,
              surveyEngine.surveyAnswers,
            );
            if (skipLogic) {
              if (skipLogic.type === "end_of_survey") {
                surveyEngine.handleCompleteSubmission();
                setIsEnd(true);
                setIsLoading(false);
                setShowQuestion(true);
              } else if (skipLogic.type === "end_of_block") {
                surveyEngine
                  .handleGetNextBlock(surveyEngine.blockQuestions.next_flow_element_id)
                  .then(_ => {
                    setPageIndex(0);
                    setIsLoading(false);
                    setShowQuestion(true);
                  });
              } else if (skipLogic.type === "question") {
                for (
                  let i = pageIndex + 1;
                  i < surveyEngine.blockQuestions.results.length;
                  i++
                ) {
                  const tempPageQuestions = handlePageQuestions(i);
                  const questionIndex = tempPageQuestions.findIndex(
                    qu => qu.id === skipLogic.destination_question_id,
                  );
                  if (questionIndex > -1) {
                    skipPageIndex = i;
                    setSkipPageQuestionIndex(questionIndex);
                    break;
                  }
                }
              }
            }
          }
          if (skipPageIndex) {
            setPageIndex(skipPageIndex);
            setIsLoading(false);
            setShowQuestion(true);
          } else if (pageIndex === surveyEngine.blockQuestions.results.length - 1) {
            if (surveyEngine.blockQuestions.next_flow_element_id === null) {
              surveyEngine.handleCompleteSubmission();
              setIsEnd(true);
              setIsLoading(false);
              setShowQuestion(true);
            } else {
              surveyEngine
                .handleGetNextBlock(surveyEngine.blockQuestions.next_flow_element_id)
                .then(_ => {
                  setPageIndex(0);
                  setIsLoading(false);
                  setShowQuestion(true);
                });
            }
          } else {
            setPageIndex(pageIndex + 1);
            setIsLoading(false);
            setShowQuestion(true);
          }
        });
    } else {
      setIsLoading(false);
      setShowQuestion(true);
    }
  };

  const handlePageQuestions = pageIndex => {
    const currentPageQuestions = surveyEngine.blockQuestions.results[
      pageIndex
    ].questions.filter(question =>
      checkQuestion(question, surveyEngine.surveyQuestions, surveyEngine.surveyAnswers),
    );
    if (currentPageQuestions.length === 0) {
      handleNextButton();
    }
    return currentPageQuestions;
  };

  useEffect(() => {
    if (surveyEngine.blockQuestions) {
      if (surveyEngine.blockQuestions.results.length === 0) {
        surveyEngine.handleCompleteSubmission();
        setIsEnd(true);
      } else {
        if (surveyEngine.blockQuestions.results.length > 0) {
          let currPageQuestions = handlePageQuestions(pageIndex);
          if (skipPageQuestionIndex) {
            currPageQuestions = currPageQuestions.slice(skipPageQuestionIndex);
            setSkipPageQuestionIndex(null);
          }
          setPageQuestions(currPageQuestions);
        }
      }
    }
  }, [surveyEngine.blockQuestions, pageIndex]);

  useEffect(() => {
    setTimeout(() => {
      setShowLoadingGif(false);
    }, 3000);
  }, []);
  const percentage = surveyEngine.isCompleted
    ? 100
    : !surveyEngine.remainingQuestionCount
      ? 0
      : (
          (surveyEngine.totalQuestionCount - surveyEngine.remainingQuestionCount) /
          surveyEngine.totalQuestionCount
        ).toFixed(2);

  if (pageQuestions === null) {
    return <>Loading</>;
  }

  if (isEnd || surveyEngine.isCompleted) {
    return <EndPage />;
  }

  if (!surveyEngine?.blockQuestions || showLoadingGif) {
    return <GifSplashScreen maxHeight={maxHeight} />;
  }

  // const marginTopCalculate =
  // const marginBottomCalculate =

  return (
    <ThemeProvider theme={newTheme}>
      <GlobalStyles
        styles={{
          body: {
            backgroundColor: backgroundSettings?.backgroundColor,
            overflow: "hidden",
          },
        }}
      />
      <Box
        sx={{
          flex: 1,
          // padding: 20,
          overflowYy: "auto" /* To handle overflow content */,
          // p: 2,
          // backgroundColor: backgroundSettings?.backgroundColor, // Optional background color
        }}
      >
        <Box
          sx={{
            width: 720,
            maxWidth: "100%", // Width of the iPhone frame in mobile view
            height: "auto",
            maxHeight: "auto",
            border: "none",
            borderRadius: "none", // Outer iPhone frame border-radius
            paddingTop: 0, // Outer iPhone frame border-radius
            position: "relative",
            overflow: "hidden",
            mx: "auto",
            // mt: 2,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "auto",
              maxHeight: preview ? maxHeight : "auto",
              width: "100%",
            }}
          >
            {!preview &&
              (logoSettings?.verticalPlacement === "Top" ||
                (generalSettings?.progressBar !== "None" &&
                  generalSettings?.progressBarVerticalPlacement === "Top")) && (
                <AppBar
                  position={"fixed"}
                  sx={{
                    left: 0,
                    justifySelf: "center",
                    width: 720,
                    maxWidth: "100%",
                  }}
                >
                  <Box
                    sx={{
                      flex: "0 0 100px", // Sabit yükseklik
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "#f5f5f5",
                      flexDirection: "column",
                      padding: 1,
                    }}
                  >
                    {logoSettings?.verticalPlacement === "Top" && (
                      <Stack
                        width="100%"
                        height="100%"
                        flexDirection="column"
                        alignItems={
                          logoSettings?.placement === "Left"
                            ? "flex-start" // Sol hizalama
                            : logoSettings?.placement === "Right"
                              ? "flex-end" // Sağ hizalama
                              : "center" // Orta hizalama
                        }
                        justifyContent="center" // Dikeyde ortalama
                      >
                        <Box width={200} display="flex" justifyContent="center" m={1}>
                          {logoSettings?.logo ? (
                            <img
                              src={logoSettings?.logo}
                              alt="Selected"
                              width={100}
                              style={{
                                maxHeight: Number(logoSettings?.maxHeight) || "auto",
                                maxWidth: Number(logoSettings?.maxWidth) || "auto",
                                objectFit: "contain", // Ensure image fits within container
                              }}
                            />
                          ) : (
                            <img
                              className="logo"
                              src="/logo.png"
                              alt="Galata Anket logo"
                              width="71"
                              height="50"
                            />
                          )}
                        </Box>
                      </Stack>
                    )}

                    {generalSettings?.progressBar !== "None" &&
                      generalSettings?.progressBarVerticalPlacement === "Top" && (
                        <Stack
                          width="100%" // Konteyner genişliğini tam genişlik yapın
                          alignItems={
                            generalSettings?.progressBarPlacement === "Left"
                              ? "flex-start" // Sola hizala
                              : generalSettings?.progressBarPlacement === "Right"
                                ? "flex-end" // Sağa hizala
                                : "center" // Ortala
                          }
                          justifyContent="center" // Dikey ortala
                          direction="column" // Dikey yerleşim
                        >
                          <Box width={200} textAlign={"center"} m={1}>
                            <Box width="100%">
                              <LinearProgress
                                variant="determinate"
                                value={percentage * 100}
                              />
                            </Box>
                            {generalSettings?.progressBar === "WithText" && (
                              <Typography
                                variant="body2"
                                align="center"
                                sx={{color: "black"}}
                              >
                                {percentage * 100}%
                              </Typography>
                            )}
                          </Box>
                        </Stack>
                      )}
                  </Box>
                </AppBar>
              )}
            {preview &&
              (logoSettings?.verticalPlacement === "Top" ||
                (generalSettings?.progressBar !== "None" &&
                  generalSettings?.progressBarVerticalPlacement === "Top")) && (
                <Box
                  sx={{
                    flex: "0 0 100px", // Sabit yükseklik
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#f5f5f5",
                    flexDirection: "column",
                    padding: 1,
                  }}
                >
                  {logoSettings?.verticalPlacement === "Top" && (
                    <Stack
                      width="100%"
                      height="100%"
                      flexDirection="column"
                      alignItems={
                        logoSettings?.placement === "Left"
                          ? "flex-start" // Sol hizalama
                          : logoSettings?.placement === "Right"
                            ? "flex-end" // Sağ hizalama
                            : "center" // Orta hizalama
                      }
                      justifyContent="center" // Dikeyde ortalama
                    >
                      <Box width={200} display="flex" justifyContent="center" m={1}>
                        {logoSettings?.logo ? (
                          <img
                            src={logoSettings?.logo}
                            alt="Selected"
                            width={100}
                            style={{
                              maxHeight: Number(logoSettings?.maxHeight) || "auto",
                              maxWidth: Number(logoSettings?.maxWidth) || "auto",
                              objectFit: "contain", // Ensure image fits within container
                            }}
                          />
                        ) : (
                          <img
                            className="logo"
                            src="/logo.png"
                            alt="Galata Anket logo"
                            width="71"
                            height="50"
                          />
                        )}
                      </Box>
                    </Stack>
                  )}

                  {generalSettings?.progressBar !== "None" &&
                    generalSettings?.progressBarVerticalPlacement === "Top" && (
                      <Stack
                        width="100%" // Konteyner genişliğini tam genişlik yapın
                        alignItems={
                          generalSettings?.progressBarPlacement === "Left"
                            ? "flex-start" // Sola hizala
                            : generalSettings?.progressBarPlacement === "Right"
                              ? "flex-end" // Sağa hizala
                              : "center" // Ortala
                        }
                        justifyContent="center" // Dikey ortala
                        direction="column" // Dikey yerleşim
                      >
                        <Box width={200} textAlign={"center"} m={1}>
                          <Box width="100%">
                            <LinearProgress
                              variant="determinate"
                              value={percentage * 100}
                            />
                          </Box>
                          {generalSettings?.progressBar === "WithText" && (
                            <Typography
                              variant="body2"
                              align="center"
                              sx={{color: "black"}}
                            >
                              {percentage * 100}%
                            </Typography>
                          )}
                        </Box>
                      </Stack>
                    )}
                </Box>
              )}

            <Box
              sx={{
                flex: "1 1 auto",
                overflowY: "auto",
                paddingTop: 2,
                paddingBottom: 2,
                marginTop: preview ? 0 : "130px",
                marginBottom: preview ? 2 : "160px",
              }}
            >
              {motionSettings?.pageTransition === "slide" ? (
                <Slide
                  direction={motionSettings?.translationDirection}
                  in={showQuestion}
                  mountOnEnter
                  unmountOnExit
                >
                  {/* Orta Kısım - Kaydırılabilir Sorular */}
                  <Stack sx={{paddingLeft: 2, paddingRight: 2}}>
                    {pageQuestions.map((question, index) => (
                      <Paper
                        key={index}
                        elevation={3}
                        sx={{
                          mb: 2,
                          p: 4,
                          backgroundColor: "rgba(255, 255, 255, 0.9)", // Hafif şeffaf bir arka plan
                          borderRadius: 3, // Yuvarlatılmış köşeler
                          boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)", // Daha belirgin bir gölge
                        }}
                      >
                        <Box key={question.id} sx={{my: 4}}>
                          <Typography
                            textAlign={"justify"}
                            dangerouslySetInnerHTML={{__html: question.text}}
                          />
                          <Collapse
                            in={
                              pageErrors[question.id] && pageErrors[question.id] !== true
                            }
                          >
                            <Alert severity="error">
                              <strong>{t(pageErrors[question.id])}</strong>
                            </Alert>
                          </Collapse>
                          <QuestionFactory question={question} />
                        </Box>
                      </Paper>
                    ))}
                  </Stack>
                </Slide>
              ) : (
                <Animation in={showQuestion}>
                  {/* Orta Kısım - Kaydırılabilir Sorular */}
                  <Stack sx={{paddingLeft: 2, paddingRight: 2}}>
                    {pageQuestions.map((question, index) => (
                      <Paper
                        key={index}
                        elevation={3}
                        sx={{
                          mb: 2,
                          p: 4,
                          backgroundColor: "rgba(255, 255, 255, 0.9)", // Hafif şeffaf bir arka plan
                          borderRadius: 3, // Yuvarlatılmış köşeler
                          boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)", // Daha belirgin bir gölge
                        }}
                      >
                        <Box key={question.id} sx={{my: 4}}>
                          <Typography
                            textAlign={"justify"}
                            dangerouslySetInnerHTML={{__html: question.text}}
                          />
                          <Collapse
                            in={
                              pageErrors[question.id] && pageErrors[question.id] !== true
                            }
                          >
                            <Alert severity="error">
                              <strong>{t(pageErrors[question.id])}</strong>
                            </Alert>
                          </Collapse>
                          <QuestionFactory question={question} />
                        </Box>
                      </Paper>
                    ))}
                  </Stack>
                </Animation>
              )}
            </Box>

            {/* Alt Kısım - Sabit İleri ve Geri Butonları */}

            <AppBar
              position={"fixed"}
              color="primary"
              sx={{
                top: "auto",
                bottom: 0,
                left: 0,
                right: 0,
                width: 720,
                maxWidth: "100%",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <Box
                sx={{
                  flexDirection: "row",
                  flex: "0 0 80px", // Sabit yükseklik
                  display: "flex",
                  justifyContent: "space-between",
                  justifySelf: "center",
                  alignItems: "center",
                  padding: "0 20px",
                  backgroundColor: "#f5f5f5",
                }}
              >
                {surveyEngine.survey.show_previous_button ? (
                  <Button
                    disabled={isLoading}
                    loading={isLoading}
                    variant="contained"
                    // onClick={() => handleButton(-1)}
                    // disabled={currentQuestion === 0}
                  >
                    {generalSettings?.previousButton
                      ? generalSettings?.previousButton
                      : "Previous"}
                  </Button>
                ) : (
                  <div />
                )}

                <LoadingButton
                  disabled={isLoading}
                  loading={isLoading}
                  variant="contained"
                  onClick={handleNextButton}
                >
                  {generalSettings?.nextButton ? generalSettings?.nextButton : t("next")}
                </LoadingButton>
              </Box>
            </AppBar>

            {(logoSettings?.verticalPlacement === "Bottom" ||
              (generalSettings?.progressBar !== "None" &&
                generalSettings?.progressBarVerticalPlacement === "Bottom")) && (
              <AppBar position="fixed" color="primary" sx={{top: "auto", bottom: 80}}>
                <Box
                  sx={{
                    flex: "0 0 80px", // Sabit yükseklik
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    backgroundColor: "#f5f5f5",
                    flexDirection: "column",
                    padding: 1,
                  }}
                >
                  {logoSettings?.verticalPlacement === "Bottom" && (
                    <Stack
                      width="100%"
                      height="100%"
                      flexDirection="column"
                      alignItems={
                        logoSettings?.placement === "Left"
                          ? "flex-start" // Sol hizalama
                          : logoSettings?.placement === "Right"
                            ? "flex-end" // Sağ hizalama
                            : "center" // Orta hizalama
                      }
                      justifyContent="center" // Dikeyde ortalama
                    >
                      <Box width={200} display="flex" justifyContent="center">
                        {logoSettings?.logo ? (
                          <img
                            src={logoSettings?.logo}
                            alt="Selected"
                            width={100}
                            style={{
                              maxHeight: logoSettings?.maxHeight,
                            }}
                          />
                        ) : (
                          <img
                            className="logo"
                            src="/logo.png"
                            alt="Galata Anket logo"
                            width="71"
                            height="50"
                          />
                        )}
                      </Box>
                    </Stack>
                  )}
                  {generalSettings?.progressBar !== "None" &&
                    generalSettings?.progressBarVerticalPlacement === "Bottom" && (
                      <Stack
                        width="100%" // Konteyner genişliğini tam genişlik yapın
                        alignItems={
                          generalSettings?.progressBarPlacement === "Left"
                            ? "flex-start" // Sola hizala
                            : generalSettings?.progressBarPlacement === "Right"
                              ? "flex-end" // Sağa hizala
                              : "center" // Ortala
                        }
                        justifyContent="center" // Dikey ortala
                        direction="column" // Dikey yerleşim
                      >
                        <Box width={200} textAlign={"center"} m={2}>
                          <Box width="100%">
                            <LinearProgress variant="determinate" value={percentage} />
                          </Box>
                          {generalSettings?.progressBar === "WithText" && (
                            <Typography variant="body2" align="center">
                              {percentage}%
                            </Typography>
                          )}
                        </Box>
                      </Stack>
                    )}
                </Box>
              </AppBar>
            )}
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
};
