import React, {useEffect, useState} from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Container,
  IconButton,
  Box,
  Tabs,
  Tab,
  Skeleton,
  Button,
} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {paths} from "src/pages/paths";
import {superRewardService} from "src/services/super/project/reward";
import {RewardRequest} from "src/entities/project/reward-request";
import TablePagination from "@mui/material/TablePagination";
import {isMobile} from "react-device-detect";
import GenericCard from "src/components/generic/GenericCard";

export const ParticipantsPaymentRequests = () => {
  const {t} = useTranslation();
  const [paymentRequests, setPaymentRequests] = useState([]);
  const [paymentRequestCount, setPaymentRequestCount] = useState(0);
  const [page, setPage] = useState(0);
  const [activeTab, setActiveTab] = useState("pending");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const fetchRewardRequests = (_page, status) => {
    setLoading(true);
    superRewardService
      .listRewardRequests(_page + 1, status)
      .then(response => {
        const results = response.results.map(res => new RewardRequest(res));
        setPaymentRequests(results);
        setPaymentRequestCount(response.count);
        setPage(_page);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchRewardRequests(page, activeTab);
  }, [activeTab]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    setPage(0);
  };

  const renderTableBody = () => {
    if (loading) {
      return Array.from(new Array(5)).map((_, index) => (
        <TableRow key={index}>
          <TableCell>
            <Skeleton variant="text" width={100} />
          </TableCell>
          <TableCell>
            <Skeleton variant="text" width={200} />
          </TableCell>
          <TableCell>
            <Skeleton variant="text" width={200} />
          </TableCell>
          <TableCell>
            <Skeleton variant="text" width={80} />
          </TableCell>
          <TableCell>
            <Skeleton variant="text" width={100} />
          </TableCell>
          <TableCell>
            <Skeleton variant="circular" width={24} height={24} />
          </TableCell>
        </TableRow>
      ));
    }

    return paymentRequests.map(paymentRequest => (
      <TableRow key={paymentRequest.id} hover style={{cursor: "pointer"}}>
        <TableCell>
          <Box sx={{display: "flex", alignItems: "center"}}>
            <FiberManualRecordIcon
              fontSize="small"
              // Duruma göre renk ayarı
              color={
                paymentRequest.status === "approved"
                  ? "success"
                  : paymentRequest.status === "pending"
                    ? "warning"
                    : "error" // rejected için kırmızı
              }
              sx={{marginRight: 1}}
            />
            {paymentRequest.status}
          </Box>
        </TableCell>
        <TableCell>{paymentRequest.contact}</TableCell>
        <TableCell>{paymentRequest.participant_id}</TableCell>
        <TableCell>${paymentRequest.amount}</TableCell>
        <TableCell>{paymentRequest.type}</TableCell>
        <TableCell>
          <IconButton
            onClick={() =>
              navigate(
                paths.dashboard.participantPaymentRequest.details.replace(
                  ":id",
                  paymentRequest.id,
                ),
              )
            }
          >
            <ArrowForwardIosIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    ));
  };

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" gutterBottom sx={{marginTop: 4, marginBottom: 3}}>
        {t("participantPaymentRequests.participantPaymentRequests")}
      </Typography>

      <Box sx={{borderBottom: 1, borderColor: "divider", mb: 2}}>
        <Tabs value={activeTab} onChange={handleTabChange}>
          <Tab label={t("participantPaymentRequests.pending")} value="pending" />
          <Tab label={t("participantPaymentRequests.approved")} value="approved" />
        </Tabs>
      </Box>

      {isMobile ? (
        paymentRequests.map(paymentRequest => (
          <GenericCard
            key={paymentRequest.id}
            data={{
              unExtendedData: [
                {
                  title: t("participantPaymentRequests.requestedStatus"),
                  specialDescription: (
                    <Box sx={{display: "flex", alignItems: "center"}}>
                      <FiberManualRecordIcon
                        fontSize="small"
                        // Duruma göre renk ayarı
                        color={
                          paymentRequest.status === "approved"
                            ? "success"
                            : paymentRequest.status === "pending"
                              ? "warning"
                              : "error" // rejected için kırmızı
                        }
                        sx={{marginRight: 1}}
                      />
                      {paymentRequest.status}
                    </Box>
                  ),
                },
                {
                  title: t("participantPaymentRequests.participantEmail"),
                  description: paymentRequest.contact,
                },
                {
                  title: t("participantPaymentRequests.participantId"),
                  description: paymentRequest.participant_id,
                },
                {
                  title: t("participantPaymentRequests.requestedAmount"),
                  description: paymentRequest.amount,
                },
                {
                  title: t("participantPaymentRequests.requestedMarket"),
                  description: paymentRequest.type,
                },
                // {
                //   title: t(
                //     "participantPaymentRequests.participantCompletedSurveyDuration",
                //   ),
                //   description: "",
                // },
                {
                  specialChildren: (
                    <Button
                      onClick={() =>
                        navigate(
                          paths.dashboard.participantPaymentRequest.details.replace(
                            ":id",
                            paymentRequest.id,
                          ),
                        )
                      }
                      variant="contained"
                      color="primary"
                      endIcon={<ArrowForwardIosIcon />}
                      sx={{
                        mt: 2,
                        ml: "auto",
                        textTransform: "none",
                        fontWeight: "bold",
                        padding: "8px 16px",
                      }}
                    >
                      {t("goToDetail")}
                    </Button>
                  ),
                },
              ],
            }}
          />
        ))
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t("participantPaymentRequests.requestedStatus")}</TableCell>
                <TableCell>{t("participantPaymentRequests.participantEmail")}</TableCell>
                <TableCell>{t("participantPaymentRequests.participantId")}</TableCell>
                <TableCell>{t("participantPaymentRequests.requestedAmount")}</TableCell>
                <TableCell>{t("participantPaymentRequests.requestedMarket")}</TableCell>
                <TableCell>
                  {t("participantPaymentRequests.participantCompletedSurveyDuration")}
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{renderTableBody()}</TableBody>
          </Table>
        </TableContainer>
      )}
      <TablePagination
        component="div"
        count={paymentRequestCount}
        onPageChange={(e, newPage) => fetchRewardRequests(newPage, activeTab)}
        onRowsPerPageChange={() => {}}
        page={page}
        rowsPerPage={10}
        rowsPerPageOptions={[10]}
      />
    </Container>
  );
};
