import "src/css/react-query-builder.css";

import {formatQuery, QueryBuilder} from "react-querybuilder";
import React, {useEffect, useMemo, useState} from "react";
import {projectService} from "src/services/project/project";
import {GifSplashScreen} from "src/components/gif-splash-screen";
import {superParticipantService} from "src/services/super/participant";
import {ParticipantTable} from "src/pages/dashboard/super/manage-participant-groups/participant-table";
import {Participant, ParticipantGroup} from "src/entities/participant";
import {Card, Grid, TextField, Typography} from "@mui/material";
import {Container} from "@mui/system";
import {LoadingButton} from "@mui/lab";
import {useTranslation} from "react-i18next";
import {ParticipantGroupsTable} from "src/pages/dashboard/super/manage-participant-groups/participant-groups-table";

const initialQuery = {
  combinator: "and",
  rules: [],
};

export const ManageParticipantGroups = () => {
  const {t} = useTranslation();
  const [query, setQuery] = useState(initialQuery);
  const [fields, setFields] = useState(null);
  const [participantGroupsTablePage, setParticipantGroupsTablePage] = useState(0);
  const [participantGroups, setParticipantGroups] = useState([]);
  const [participantGroupCount, setParticipantGroupCount] = useState(0);

  const [teamMembersTablePage, setTeamMembersTablePage] = useState(0);
  const [teamMembers, setTeamMembers] = useState([]);
  const [teamMembersCount, setTeamMembersCount] = useState(0);
  const [participantGroupName, setParticipantGroupName] = useState("");
  const [loadingSearchButton, setLoadingSearchButton] = useState(false);
  const [loadingCreateGroupButton, setLoadingCreateGroupButton] = useState(false);

  const fetchParticipantGroups = page => {
    superParticipantService.listParticipantGroups(page + 1).then(response => {
      setParticipantGroups(response.results.map(res => new ParticipantGroup(res)));
      setParticipantGroupCount(response.count);
      setParticipantGroupsTablePage(page);
    });
  };

  useEffect(() => {
    const filters = {is_published: true};
    projectService.listProjects(1, filters).then(res => {
      const projects = res.results.map(res => ({name: res.id, label: res.title}));
      setFields([
        {
          name: "project_rewarded",
          label: "Project",
          operators: [{value: "project_rewarded", label: "Project Reward"}],
          valueEditorType: "select",
          values: projects,
        },
      ]);
    });
    fetchParticipantGroups(participantGroupsTablePage);
  }, []);

  if (fields === null) {
    return <GifSplashScreen />;
  }

  const handleSearchParticipant = page => {
    setLoadingSearchButton(true);
    const formattedQuery = formatQuery(query, "json_without_ids");
    superParticipantService
      .searchParticipant(page + 1, {
        filter_query: JSON.parse(formattedQuery),
      })
      .then(response => {
        setTeamMembers(response.results.map(res => new Participant(res)));
        setTeamMembersCount(response.count);
        setTeamMembersTablePage(page);
        setLoadingSearchButton(false);
      });
  };

  const handleCreateParticipantGroup = _ => {
    setLoadingCreateGroupButton(true);
    const formattedQuery = formatQuery(query, "json_without_ids");
    const payload = {
      name: participantGroupName,
      search_filters: {filter_query: JSON.parse(formattedQuery)},
    };
    superParticipantService.createProjectAssignmentGroup(payload).then(_ => {
      setLoadingCreateGroupButton(false);
    });
  };

  const handleEditParticipantGroup = async (id, payload) => {
    const response = await superParticipantService.editParticipantGroups(id, payload);
    const updatedParticipantGroup = new ParticipantGroup(response);
    setParticipantGroups(prevParticipantGroups =>
      prevParticipantGroups.map(item =>
        item.id === id ? updatedParticipantGroup : item,
      ),
    );
  };

  return (
    <Container maxWidth="lg">
      <ParticipantGroupsTable
        groups={participantGroups}
        count={participantGroupCount}
        page={participantGroupsTablePage}
        handlePageChange={fetchParticipantGroups}
        handleEditParticipantGroup={handleEditParticipantGroup}
      />
      <QueryBuilder fields={fields} query={query} onQueryChange={setQuery} />
      <Grid container sx={{m: 2}}>
        <Grid item xs={6} sx={{px: 1}}>
          <LoadingButton
            fullWidth
            loading={loadingSearchButton}
            onClick={() => handleSearchParticipant(teamMembersTablePage)}
            sx={{mt: 3}}
            type="submit"
            variant="contained"
          >
            {t("manageParticipantGroups.searchParticipant")}
          </LoadingButton>
        </Grid>
        <Grid item xs={6} sx={{px: 1}}>
          <TextField
            fullWidth
            label={"Name"}
            variant="outlined"
            value={participantGroupName}
            onChange={e => setParticipantGroupName(e.target.value)}
          />
          <LoadingButton
            fullWidth
            loading={loadingCreateGroupButton}
            onClick={handleCreateParticipantGroup}
            sx={{mt: 3}}
            type="submit"
            variant="contained"
          >
            {t("manageParticipantGroups.createParticipantGroup")}
          </LoadingButton>
        </Grid>
      </Grid>
      <ParticipantTable
        teamMembers={teamMembers}
        count={teamMembersCount}
        page={teamMembersTablePage}
        handlePageChange={handleSearchParticipant}
      />
    </Container>
  );
};
