import {useTranslation} from "react-i18next";
import SvgIcon from "@mui/material/SvgIcon";
import {paths} from "src/pages/paths";
import BuildingIcon from "src/icons/building";
import BallotIcon from "@mui/icons-material/Ballot"; // Import the AddToPhotos icon
import AddToPhotosIcon from "@mui/icons-material/AddToPhotos"; // Import the AddToPhotos icon
import FindParticiIcon from "@mui/icons-material/GroupAdd"; // Import the AddToPhotos icon
import SettingsIcon from "@mui/icons-material/Settings"; // Import the AddToPhotos icon
import UploadFileIcon from "@mui/icons-material/UploadFile";
import {useAuth} from "src/hooks/use-auth";

export const useSections = user => {
  const auth = useAuth();
  const {t} = useTranslation();

  const surveys = {
    title: t("mySurveys"),
    path: paths.dashboard.surveys.list,
    icon: (
      <SvgIcon fontSize="small">
        <BallotIcon />
      </SvgIcon>
    ),
  };

  const newsurvey = {
    title: t("newSurvey"),
    path: "/dashboard/newsurvey",
    icon: (
      <SvgIcon fontSize="small">
        <AddToPhotosIcon />
      </SvgIcon>
    ),
  };

  const findparticipants = {
    title: t("findparticipants"),
    path: "/dashboard/findparticipants",
    icon: (
      <SvgIcon fontSize="small">
        <FindParticiIcon />
      </SvgIcon>
    ),
  };

  const settingscategory = {
    title: t("settingcategory"),
    path: "/dashboard/settingcategory",
    icon: (
      <SvgIcon fontSize="small">
        <SettingsIcon />
      </SvgIcon>
    ),
  };
  const findParticipantsRequest = {
    title: t("findParticipantsRequest.findParticipantsRequest"),
    path: paths.dashboard.participantRequests.list,
    icon: (
      <SvgIcon fontSize="small">
        <FindParticiIcon />
      </SvgIcon>
    ),
  };

  const excellToSurvey = {
    title: t("excellToSurvey"),
    path: "/dashboard/excelltosurvey",
    icon: (
      <SvgIcon fontSize="small">
        <UploadFileIcon />
      </SvgIcon>
    ),
  };

  const participantPaymentRequest = {
    title: t("participantPaymentRequests.participantPaymentRequests"),
    path: paths.dashboard.participantPaymentRequest.list,
    icon: (
      <SvgIcon fontSize="small">
        <FindParticiIcon />
      </SvgIcon>
    ),
  };

  const manageParticipantGroups = {
    title: t("manageParticipantGroups.manageParticipantGroups"),
    path: paths.dashboard.manageParticipantGroups.list,
    icon: (
      <SvgIcon fontSize="small">
        <FindParticiIcon />
      </SvgIcon>
    ),
  };

  const manageParticipantRewards = {
    title: t("manageParticipantRewards"),
    path: paths.dashboard.manageParticipantRewards.index,
    icon: (
      <SvgIcon fontSize="small">
        <FindParticiIcon />
      </SvgIcon>
    ),
  };

  const inviteParticipant = {
    title: t("inviteParticipant"),
    path: paths.dashboard.inviteParticipant.index,
    icon: (
      <SvgIcon fontSize="small">
        <FindParticiIcon />
      </SvgIcon>
    ),
  };

  const teams = {
    title: "Teams",
    path: paths.dashboard.teams.list,
    icon: (
      <SvgIcon fontSize="small">
        <BuildingIcon />
      </SvgIcon>
    ),
  };

  if (auth.user.is_superuser) {
    return [
      surveys,
      newsurvey,
      findparticipants,
      settingscategory,
      findParticipantsRequest,
      excellToSurvey,
      participantPaymentRequest,
      manageParticipantGroups,
      manageParticipantRewards,
      inviteParticipant,
      teams,
    ];
  }
  return [
    surveys,
    newsurvey,
    findparticipants,
    settingscategory,
    findParticipantsRequest,
    excellToSurvey,
    participantPaymentRequest,
  ];
};
