import {Container} from "@mui/system";
import {CompletedProjects} from "src/pages/admin/teams/team-member-details/completed-projects";
import {useNavigate} from "react-router-dom";
import {Button, Box} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export const TeamMemberDetails = props => {
  const navigate = useNavigate();

  return (
    <Container maxWidth="xl">
      <Box sx={{mb: 3, mt: 2}}>
        <Button
          onClick={() => navigate(-1)}
          startIcon={<ArrowBackIcon />}
          color="primary"
        >
          Back
        </Button>
      </Box>

      {/* Completed Projects*/}
      <CompletedProjects />
    </Container>
  );
};
