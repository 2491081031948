export const trSurveyTranslations = {
  survey: "Anket",
  next: "İleri",
  prev: "Geri",
  pageErrors: "Hatalar var. Cevaplarınızı kontrol ediniz.",
  requiredQuestionError: "Bu sorunun cevaplanması gerekmektedir.",
  integerInputTypeError: "Lütfen bir sayı giriniz.",
  copySurveyURL: "Anket linkini kopyala",
  copySurveyURLSuccessful: "Anket linki kopyalandı, paylaşabilirsiniz.",
  other: "Diğer",
  otherOptionEmptyError: "Seçenek boş bırakılamaz.",
  defaultEndPageMessage: "Katılımınız için teşekkür ederiz!",
  surveyUrlCopied: "Anket URL'si başarıyla kopyalandı!",
  surveyUrlNotCopied: "Anket URL'si kopyalanamadı!",
  copySurveyLink: "Anket Bağlantısını Kopyala",
};
