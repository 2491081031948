import React from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";
import {useTranslation} from "react-i18next";

const LogoSettings = ({logoSettings, setLogoSettings}) => {
  const {t} = useTranslation();
  const handleLogoChange = event => {
    const file = event.target.files[0];
    if (file) {
      setLogoSettings({...logoSettings, logo: URL.createObjectURL(file)});
    }
  };

  return (
    <Box>
      <Box>
        <Typography variant="subtitle1">{t("designTab.logoStyles.logoImage")}</Typography>
        <Box display="flex" flexDirection="column" alignItems="center" mt={1} mb={1}>
          <Box
            sx={{
              mb: 1,
              justifyContent: "center",
              alignContent: "center",
              width: "100%",
              height: 200,
              background: `url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAIAAADZF8uwAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAB3RJTUUH4gYcESgn52TfKAAAACdJREFUGNNjfPHiBQMqEBMTQxNhYiACDEZFjP/+/UMTevXq1XDxHQDt+QjJCn+2YwAAAABJRU5ErkJggg==)`,
            }}
          >
            {logoSettings?.logo ? (
              <img
                src={logoSettings?.logo}
                alt="Logo"
                style={{maxWidth: "100%", maxHeight: "100%"}}
              />
            ) : (
              <div className="fallback" />
            )}
          </Box>
          <Button variant="contained" component="label" sx={{ml: 2}}>
            {t("designTab.logoStyles.change")}
            <input
              accept="image/*"
              id="imageInput"
              type="file"
              style={{display: "none"}}
              onChange={handleLogoChange}
            />
          </Button>
        </Box>
      </Box>

      <Box mt={2}>
        <FormControl fullWidth>
          <InputLabel id="lfe-logo-placement-label">
            {t("designTab.logoStyles.placement")}
          </InputLabel>
          <Select
            labelId="lfe-logo-placement-label"
            id="lfe-logo-placement"
            value={logoSettings?.placement}
            label={t("designTab.logoStyles.placement")}
            onChange={e => setLogoSettings({...logoSettings, placement: e.target.value})}
          >
            <MenuItem value="Left">{t("designTab.similars.left")}</MenuItem>
            <MenuItem value="Center">{t("designTab.similars.center")}</MenuItem>
            <MenuItem value="Right">{t("designTab.similars.right")}</MenuItem>
          </Select>
        </FormControl>
      </Box>

      <Box mt={2}>
        <FormControl fullWidth>
          <InputLabel id="vertical-placement-label">
            {t("designTab.logoStyles.verticalPlacement")}
          </InputLabel>
          <Select
            labelId="vertical-placement-label"
            id="vertical-placement-select"
            value={logoSettings?.verticalPlacement}
            label={t("designTab.logoStyles.verticalPlacement")}
            onChange={e =>
              setLogoSettings({...logoSettings, verticalPlacement: e.target.value})
            }
          >
            <MenuItem value="Top">{t("designTab.similars.top")}</MenuItem>
            <MenuItem value="Bottom">{t("designTab.similars.bottom")}</MenuItem>
          </Select>
        </FormControl>
      </Box>

      <Box mt={2}>
        <TextField
          label={t("designTab.logoStyles.maxHeight")}
          id="lfe-logo-height-input"
          type="number"
          value={logoSettings?.maxHeight}
          onChange={e => setLogoSettings({...logoSettings, maxHeight: e.target.value})}
          InputProps={{endAdornment: <span>px</span>}}
          fullWidth
        />
      </Box>

      <Box mt={2}>
        <TextField
          label={t("designTab.logoStyles.maxWidth")}
          id="lfe-logo-mobile-scale-input"
          type="number"
          value={logoSettings?.maxWidth}
          onChange={e => setLogoSettings({...logoSettings, maxWidth: e.target.value})}
          InputProps={{endAdornment: <span>px</span>}}
          fullWidth
        />
      </Box>
    </Box>
  );
};

export default LogoSettings;
