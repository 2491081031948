import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TablePagination from "@mui/material/TablePagination";
import {Grid, Paper, TableContainer} from "@mui/material";
import {useTranslation} from "react-i18next";
import {calculateAge} from "src/utils/helpers";
import {isMobile} from "react-device-detect";
import GenericCard from "src/components/generic/GenericCard";

export const ParticipantTable = props => {
  const {t} = useTranslation();
  const {teamMembers, count, page, handlePageChange} = props;

  return (
    <Grid container sx={12} p={2}>
      {isMobile ? (
        teamMembers.map(teamMember => (
          <GenericCard
            key={teamMember.id}
            data={{
              unExtendedData: [
                {
                  title: "ID",
                  description: teamMember.id,
                },
                {
                  title: t("manageParticipantGroups.email"),
                  description: teamMember.email,
                },
                {
                  title: t("manageParticipantGroups.phone"),
                  description: teamMember.phone,
                },
                {
                  title: t("submittedRequests.age"),
                  description: teamMember.birth_date
                    ? calculateAge(teamMember.birth_date)
                    : "",
                },
                {
                  title: t("submittedRequests.gender"),
                  description: t(teamMember.gender?.toLowerCase()),
                },
                {
                  title: t("editInfo.country"),
                  description: teamMember.country,
                },
                {
                  title: t("editInfo.province"),
                  description: teamMember.province,
                },
              ],
            }}
          />
        ))
      ) : (
        <TableContainer component={Paper} style={{overflow: "auto"}}>
          <Table sx={{minWidth: 1150}}>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>{t("manageParticipantGroups.email")}</TableCell>
                <TableCell>{t("manageParticipantGroups.phone")}</TableCell>
                <TableCell>{t("submittedRequests.age")}</TableCell>
                <TableCell>{t("submittedRequests.gender")}</TableCell>
                <TableCell>{t("editInfo.country")}</TableCell>
                <TableCell>{t("editInfo.province")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {teamMembers.map(teamMember => (
                <TableRow key={teamMember.id}>
                  <TableCell>{teamMember.id}</TableCell>
                  <TableCell>{teamMember.email}</TableCell>
                  <TableCell>{teamMember.phone}</TableCell>
                  <TableCell>
                    {teamMember.birth_date ? calculateAge(teamMember.birth_date) : ""}
                  </TableCell>
                  <TableCell>{t(teamMember.gender?.toLowerCase())}</TableCell>
                  <TableCell>{teamMember.country}</TableCell>
                  <TableCell>{teamMember.province}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <Grid container justifyContent="flex-end">
        <TablePagination
          component="div"
          count={count}
          onPageChange={(e, newPage) => handlePageChange(newPage)}
          onRowsPerPageChange={() => {}}
          page={page}
          rowsPerPage={10}
          // rowsPerPageOptions={[5, 10, 25]}
          rowsPerPageOptions={[10]}
        />
      </Grid>
    </Grid>
  );
};
