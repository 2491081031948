export class MultipleChoiceLocator {
  constructor(props) {
    this.left_operand = props.left_operand; // question reference_id
    this.operand = props.operand;
    this.right_operand = props.right_operand; // option reference_id
  }

  set(name, value) {
    if (name === "operand") {
      Object.assign(this, {[name]: value});
    } else {
      Object.assign(this, {[name]: value?.reference_id});
    }
  }

  isValid() {
    const check_left_operand =
      this.left_operand !== null &&
      this.left_operand !== undefined &&
      this.left_operand !== "";
    const check_operand =
      this.operand !== null && this.operand !== undefined && this.operand !== "";
    const check_right_operand =
      this.right_operand !== null &&
      this.right_operand !== undefined &&
      this.right_operand !== "";
    return check_left_operand && check_operand && check_right_operand;
  }
}
