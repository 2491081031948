export class ShortTextLocator {
  constructor(props) {
    this.left_operand = props.left_operand; // question reference_id
    this.operand = props.operand;
    this.right_operand = props.right_operand;
  }

  set(name, value) {
    if (name === "left_operand") {
      Object.assign(this, {[name]: value.reference_id});
    } else {
      Object.assign(this, {[name]: value});
    }
  }

  isValid() {
    return true;
  }
}
