import {useEffect, useState} from "react";
import {superParticipantRequestService} from "src/services/super/project/participant-request";
import {RewardCondition as RewardConditionObj} from "src/entities/project/condition/reward-condition";
import {Box, Button, Card, Grid, TextField, Typography} from "@mui/material";
import {RewardCondition} from "src/pages/dashboard/super/participant-requests/participant-request-detail/reward/reward-condition";
import CircularProgress from "@mui/material/CircularProgress";
import {expressionLogicType} from "src/schemas/condition/expression-logic-type";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";

export const Reward = props => {
  const {id} = useParams();
  const {projectQuestions} = props;
  const {t} = useTranslation();

  const [projectRewardAmount, setProjectRewardAmount] = useState(0);
  const [projectRewardConditions, setProjectRewardConditions] = useState(null);
  const [saveRewardConditionLoading, setSaveRewardConditionLoading] = useState(false);

  useEffect(() => {
    superParticipantRequestService.getProjectReward(id).then(response => {
      setProjectRewardAmount(response.amount);
      setProjectRewardConditions(new RewardConditionObj(response));
    });
  }, []);

  const handleCondition = (expressionIndex, name, value) => {
    projectRewardConditions.expressions[expressionIndex].setLogicType(name, value);
    setProjectRewardConditions(projectRewardConditions.clone());
  };

  const handleLocator = (expressionIndex, name, value) => {
    if (
      name === "left_operand" &&
      projectRewardConditions.expressions[expressionIndex].expression_type ===
        expressionLogicType.QUESTION
    ) {
      projectRewardConditions.expressions[expressionIndex].setQuestionLocator(value);
    }
    projectRewardConditions.expressions[expressionIndex].locator.set(name, value);
    setProjectRewardConditions(projectRewardConditions.clone());
  };

  const handleAddExpression = expressionIndex => {
    projectRewardConditions.addExpression(expressionIndex);
    setProjectRewardConditions(projectRewardConditions.clone());
  };

  const handleDeleteExpression = expressionIndex => {
    projectRewardConditions.deleteExpression(expressionIndex);
    setProjectRewardConditions(projectRewardConditions.clone());
  };

  const handleSetReward = _ => {
    setSaveRewardConditionLoading(true);
    const payload = {
      amount: projectRewardAmount,
      expressions: projectRewardConditions.expressions,
    };
    superParticipantRequestService.setProjectReward(id, payload).then(_ => {
      setSaveRewardConditionLoading(false);
    });
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xl={8} lg={8} md={8} xs={8}>
          <Card
            sx={{
              width: "100%",
              alignItems: "center",
              px: 3,
              py: 2,
              mt: 2,
              minHeight: 125,
            }}
          >
            <Typography variant="h6" component="div" sx={{py: 1}}>
              Reward Condition
            </Typography>
            {projectRewardConditions && (
              <RewardCondition
                questions={projectQuestions}
                condition={projectRewardConditions}
                handleCondition={handleCondition}
                handleLocator={handleLocator}
                handleAddExpression={handleAddExpression}
                handleDeleteExpression={handleDeleteExpression}
              />
            )}
          </Card>
        </Grid>
        <Grid item xl={4} lg={4} md={4} xs={4}>
          <Card
            sx={{
              width: "100%",
              alignItems: "center",
              px: 3,
              py: 2,
              mt: 2,
              minHeight: 125,
            }}
          >
            <Typography variant="h6" component="div" sx={{py: 1}}>
              {t("sendParticipant.rewardDefinition")}
            </Typography>
            <TextField
              fullWidth
              label={t("sendParticipant.rewardDefinition")}
              variant="outlined"
              value={projectRewardAmount}
              onChange={e => setProjectRewardAmount(e.target.value)}
              type={"number"}
            />
          </Card>
        </Grid>
      </Grid>
      <Box sx={{display: "flex", justifyContent: "flex-end", my: 2}}>
        <Button onClick={handleSetReward} variant="contained">
          {saveRewardConditionLoading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            "Set Reward"
          )}
        </Button>
      </Box>
    </>
  );
};
