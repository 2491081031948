import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import {Container} from "@mui/system";
import {useState} from "react";
import {LoadingButton} from "@mui/lab";
import {superParticipantService} from "src/services/super/participant";
import {Grid} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import {useAuth} from "src/hooks/use-auth";
import Typography from "@mui/material/Typography";

class InviteParticipantResponse {
  constructor(response) {
    this.exist = response ? response.exist : [];
    this.successful = response ? response.successful : [];
    this.error = response ? response.error : [];
  }
}

export const InviteParticipant = () => {
  const [phones, setPhones] = useState(null);
  const [emails, setEmails] = useState(null);
  const [phoneCount, setPhoneCount] = useState(0);
  const [emailCount, setEmailCount] = useState(0);
  const [resultPhones, setResultPhones] = useState(new InviteParticipantResponse());
  const [resultEmails, setResultEmails] = useState(new InviteParticipantResponse());
  const [isLoading, setIsLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const auth = useAuth();

  const handleInvite = () => {
    setIsLoading(true);
    const email_list = emails ? emails.split("\n") : [];
    const phone_list = phones ? phones.split("\n") : [];
    const payload = {
      emails: email_list,
      phones: phone_list,
    };
    superParticipantService
      .inviteParticipant(payload)
      .then(response => {
        setPhoneCount(phone_list.length);
        setEmailCount(email_list.length);
        setResultPhones(new InviteParticipantResponse(response.phones));
        setResultEmails(new InviteParticipantResponse(response.emails));
        setPhones("");
        setEmails("");
        setIsLoading(false);
        setOpenDialog(true);
      })
      .catch(err => {
        auth.showAlert("error", `Something went wrong! ${err}`);
      });
  };

  return (
    <Container maxWidth="lg">
      <Grid container spacing={3}>
        <Grid item xl={6} lg={6} md={6} xs={12}>
          <Stack sx={{mt: 2}} spacing={12} direction={"row"}>
            <TextField
              fullWidth
              label="Phones"
              placeholder={"05XXXXXXXXX"}
              value={phones}
              rows={4}
              multiline
              onChange={e => setPhones(e.target.value)}
            />
          </Stack>
        </Grid>
        <Grid item xl={6} lg={6} md={6} xs={12}>
          <Stack sx={{mt: 2}} spacing={12} direction={"row"}>
            <TextField
              fullWidth
              label="Emails"
              placeholder={"example@galataanket.com"}
              value={emails}
              rows={4}
              multiline
              onChange={e => setEmails(e.target.value)}
            />
          </Stack>
        </Grid>
      </Grid>
      <LoadingButton
        fullWidth
        loading={isLoading}
        disabled={!(emails || phones)}
        onClick={handleInvite}
        sx={{mt: 3}}
        size="large"
        type="submit"
        variant="contained"
      >
        Invite
      </LoadingButton>
      <Dialog
        fullWidth
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        maxWidth={"md"}
      >
        <DialogTitle>Result</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography>Requested sms count: {phoneCount}</Typography>
            <Grid container spacing={2}>
              <Grid item xl={4} lg={4} md={4} xs={12}>
                <Typography>
                  Exist as Participant phone count: {resultPhones.exist.length}
                </Typography>
                <Stack sx={{mt: 2}} spacing={12} direction={"row"}>
                  <TextField
                    fullWidth
                    label="Exist as Participant Phones"
                    value={resultPhones.exist.join("\n")}
                    rows={4}
                    multiline
                    disabled={true}
                  />
                </Stack>
              </Grid>
              <Grid item xl={4} lg={4} md={4} xs={12}>
                <Typography>Sent sms count: {resultPhones.successful.length}</Typography>
                <Stack sx={{mt: 2}} spacing={12} direction={"row"}>
                  <TextField
                    fullWidth
                    label="Successful Phones"
                    value={resultPhones.successful.join("\n")}
                    rows={4}
                    multiline
                    disabled={true}
                  />
                </Stack>
              </Grid>
              <Grid item xl={4} lg={4} md={4} xs={12}>
                <Typography>Error sms count: {resultPhones.error.length}</Typography>
                <Stack sx={{mt: 2}} spacing={12} direction={"row"}>
                  <TextField
                    fullWidth
                    label="Error Phones"
                    value={resultPhones.error.join("\n")}
                    rows={4}
                    multiline
                    disabled={true}
                  />
                </Stack>
              </Grid>
            </Grid>
            <Typography>Requested email count: {emailCount}</Typography>
            <Grid container spacing={2}>
              <Grid item xl={4} lg={4} md={4} xs={12}>
                <Typography>
                  Exist as Participant phone count: {resultEmails.exist.length}
                </Typography>
                <Stack sx={{mt: 2}} spacing={12} direction={"row"}>
                  <TextField
                    fullWidth
                    label="Exist as Participant Emails"
                    value={resultEmails.exist.join("\n")}
                    rows={4}
                    multiline
                    disabled={true}
                  />
                </Stack>
              </Grid>
              <Grid item xl={4} lg={4} md={4} xs={12}>
                <Typography>Sent sms count: {resultEmails.successful.length}</Typography>
                <Stack sx={{mt: 2}} spacing={12} direction={"row"}>
                  <TextField
                    fullWidth
                    label="Successful Emails"
                    value={resultEmails.successful.join("\n")}
                    rows={4}
                    multiline
                    disabled={true}
                  />
                </Stack>
              </Grid>
              <Grid item xl={4} lg={4} md={4} xs={12}>
                <Typography>Error sms count: {resultEmails.error.length}</Typography>
                <Stack sx={{mt: 2}} spacing={12} direction={"row"}>
                  <TextField
                    fullWidth
                    label="Error Emails"
                    value={resultEmails.error.join("\n")}
                    rows={4}
                    multiline
                    disabled={true}
                  />
                </Stack>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};
