import axiosInstance from "src/services/axiosInstance";

class ParticipantRequestService {
  async createParticipantRequest(payload) {
    return await axiosInstance
      .post(`/participant-requests`, payload)
      .then(response => {
        return response?.data;
      })
      .catch(err => {
        console.error("Error: ", err);
      });
  }

  async listParticipantRequests(page) {
    return await axiosInstance
      .get(`/participant-requests`, {
        params: {page: page},
      })
      .then(response => {
        return response?.data;
      })
      .catch(err => {
        console.log("err");
      });
  }
}

export const participantRequestService = new ParticipantRequestService();
