import {useTranslation} from "react-i18next";
import SvgIcon from "@mui/material/SvgIcon";
import DotsHorizontalIcon from "@untitled-ui/icons-react/build/esm/DotsHorizontal";
import IconButton from "@mui/material/IconButton";
import {useState} from "react";
import {usePopover} from "src/hooks/use-popover";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
} from "@mui/material";
import Trash02Icon from "@untitled-ui/icons-react/build/esm/Trash02";
import Edit05Icon from "@untitled-ui/icons-react/build/esm/Edit05";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import {SlideUpTransition} from "src/components/slide-up-transition";
import Button from "@mui/material/Button";
import {LoadingButton} from "@mui/lab";
import TextField from "@mui/material/TextField";
import {superParticipantService} from "src/services/super/participant";
import {useAuth} from "src/hooks/use-auth";
import CircularProgress from "@mui/material/CircularProgress";

export const ParticipantGroupActionButton = props => {
  const {t} = useTranslation();
  const {participantGroup, handleEdit} = props;
  const [participantGroupName, setParticipantGroupName] = useState(participantGroup.name);
  const [addPhones, setAddPhones] = useState(null);
  const [removePhones, setRemovePhones] = useState(null);
  const [editDialog, setEditDialog] = useState(false);
  const [editButtonLoading, setEditButtonLoading] = useState(false);
  const [downloadButtonLoading, setDownloadButtonLoading] = useState(false);
  const popover = usePopover();
  const auth = useAuth();

  function handleEditButton() {
    setEditDialog(true);
    popover.handleClose();
  }

  function handleEditParticipantGroup() {
    setEditButtonLoading(true);
    const payload = {
      name: participantGroupName,
      add: addPhones ? addPhones.split("\n") : [],
      remove: removePhones ? removePhones.split("\n") : [],
    };
    handleEdit(participantGroup.id, payload).then(response => {
      setEditButtonLoading(false);
      setEditDialog(false);
      auth.showAlert("success", t("successfullyUpdated"));
    });
  }

  function handleDownloadParticipants() {
    setDownloadButtonLoading(true);
    superParticipantService
      .exportParticipantGroupsCSV(participantGroup.id)
      .then(response => {
        setDownloadButtonLoading(false);
        popover.handleClose();
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${participantGroup.id}.csv`); // Specify the file name
        document.body.appendChild(link);
        link.click();
        link.remove();
      });
  }

  return (
    <>
      <IconButton onClick={popover.handleOpen} ref={popover.anchorRef}>
        <SvgIcon>
          <DotsHorizontalIcon />
        </SvgIcon>
      </IconButton>
      <Popover
        anchorEl={popover.anchorRef.current}
        anchorOrigin={{
          vertical: "bottom",
        }}
        disableScrollLock
        onClose={popover.handleClose}
        open={popover.open}
      >
        <MenuItem
          disableGutters
          sx={{cursor: "pointer", p: 2}}
          onClick={handleEditButton}
        >
          <ListItemIcon>
            <SvgIcon>
              <Edit05Icon />
            </SvgIcon>
          </ListItemIcon>
          <ListItemText primary={t("edit")} />
        </MenuItem>
        <MenuItem
          disableGutters
          sx={{cursor: "pointer", p: 2}}
          onClick={handleDownloadParticipants}
        >
          {downloadButtonLoading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            <>
              <ListItemIcon>
                <SvgIcon>
                  <FileDownloadOutlinedIcon />
                </SvgIcon>
              </ListItemIcon>
              <ListItemText primary={t("download")} />
            </>
          )}
        </MenuItem>
        <MenuItem disableGutters sx={{cursor: "pointer", p: 2}} onClick={() => {}}>
          <ListItemIcon>
            <SvgIcon color={"error"}>
              <Trash02Icon />
            </SvgIcon>
          </ListItemIcon>
          <ListItemText primary={t("delete")} />
        </MenuItem>
      </Popover>

      {/* EDIT DIALOG */}
      <Dialog
        open={editDialog}
        TransitionComponent={SlideUpTransition}
        keepMounted
        // onClose={handleClose}
      >
        <DialogTitle>Edit Participant Group</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{mb: 2}}>
            <TextField
              sx={{mb: 2}}
              fullWidth
              label={"Name"}
              value={participantGroupName}
              onChange={e => setParticipantGroupName(e.target.value)}
            />
            <TextField
              sx={{mb: 2}}
              fullWidth
              label="Add Phones"
              placeholder={"05XXXXXXXXX\n05XXXXXXXXX"}
              value={addPhones}
              rows={4}
              multiline
              onChange={e => setAddPhones(e.target.value)}
            />
            <TextField
              fullWidth
              label="Remove Phones"
              placeholder={"05XXXXXXXXX\n05XXXXXXXXX"}
              value={removePhones}
              rows={4}
              multiline
              onChange={e => setRemovePhones(e.target.value)}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditDialog(false)}>{t("cancel")}</Button>
          <LoadingButton
            variant="contained"
            loading={editButtonLoading}
            onClick={handleEditParticipantGroup}
          >
            {t("save")}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
