import axiosInstance from "src/services/axiosInstance";

class ParticipantService {
  async inviteParticipant(payload) {
    return await axiosInstance
      .post(`/super/invite-participant`, payload)
      .then(response => {
        return response?.data;
      })
      .catch(err => {
        throw err;
      });
  }

  async createProjectAssignmentGroup(payload) {
    return await axiosInstance
      .post(`/super/participant`, payload)
      .then(response => {
        return response?.data;
      })
      .catch(err => {
        console.log("err");
      });
  }

  async searchParticipant(page, payload) {
    return await axiosInstance
      .post(`/super/participant/search`, payload, {params: {page: page}})
      .then(response => {
        return response?.data;
      })
      .catch(err => {
        console.log("err");
      });
  }

  async listParticipantGroups(page) {
    return await axiosInstance
      .get(`/super/participant`, {params: {page: page}})
      .then(response => {
        return response?.data;
      })
      .catch(err => {
        console.log("err");
      });
  }

  async editParticipantGroups(id, payload) {
    return await axiosInstance
      .post(`/super/participant/${id}`, payload)
      .then(response => {
        return response?.data;
      })
      .catch(err => {
        console.log("err");
      });
  }

  async exportParticipantGroupsCSV(id) {
    return await axiosInstance
      .get(`/super/participant/${id}/export-csv`, {responseType: "blob"})
      .then(response => {
        return response?.data;
      })
      .catch(err => {
        console.log("err");
      });
  }
}

export const superParticipantService = new ParticipantService();
