import React, {useState} from "react";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Collapse,
  Typography,
  Grid,
  Box,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {useTranslation} from "react-i18next";

const GenericCard = ({data}) => {
  const {t} = useTranslation();
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card sx={{mb: 2, p: 0}}>
      {(data?.header?.title || data?.header?.specialTitle) && (
        <CardHeader
          {...data?.header?.props}
          title={
            <>
              {data?.header?.specialTitle || (
                <Typography variant="h6" sx={{fontWeight: "bold", color: "primary.main"}}>
                  {data?.header?.title}
                </Typography>
              )}
            </>
          }
          sx={{pt: 2, pb: 1}}
        />
      )}
      <CardContent sx={{pt: data?.header?.title || data?.header?.specialTitle ? 1 : 4}}>
        <Grid container spacing={2}>
          {data.unExtendedData.map((item, index) =>
            item?.specialChildren ? (
              item?.specialChildren
            ) : (
              <Grid item xs={12}>
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={5}>
                    <Typography
                      variant="body2"
                      sx={{
                        fontWeight: "bold",
                        wordBreak: "break-word",
                        whiteSpace: "normal",
                      }}
                    >
                      {item.specialTitle || item.title}
                    </Typography>
                  </Grid>
                  <Grid item xs={1} sx={{textAlign: "center"}}>
                    <Typography variant="body2">:</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      variant="body2"
                      sx={{wordBreak: "break-word", whiteSpace: "normal"}}
                    >
                      {item.specialDescription || item.description}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            ),
          )}
        </Grid>
      </CardContent>
      {data?.extendedData?.length && (
        <>
          <CardActions disableSpacing>
            <Box
              onClick={handleExpandClick}
              sx={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                ml: "auto",
              }}
            >
              <Typography>
                {expanded
                  ? t("generic.genericCard.showLess")
                  : t("generic.genericCard.showMore")}
              </Typography>
              <ExpandMoreIcon />
            </Box>
          </CardActions>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent sx={{pt: 1}}>
              <Grid container spacing={2}>
                {data.extendedData.map((item, index) => (
                  <Grid item xs={12}>
                    <Grid container spacing={1} alignItems="center">
                      <Grid item xs={5}>
                        <Typography variant="body2" sx={{fontWeight: "bold"}}>
                          {item.specialTitle || item.title}
                        </Typography>
                      </Grid>
                      <Grid item xs={1} sx={{textAlign: "center"}}>
                        <Typography variant="body2">:</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body2">
                          {item.specialDescription || item.description}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </CardContent>
          </Collapse>
        </>
      )}
    </Card>
  );
};

export default GenericCard;
