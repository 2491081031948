import React, {useEffect} from "react";
import {useRouter} from "src/hooks/use-router";
import {useAuth} from "src/hooks/use-auth";
import {useLocation} from "react-router-dom";
import {paths} from "src/pages/paths";
import {GifSplashScreen} from "src/components/gif-splash-screen";

export const AuthGuard = props => {
  const {children} = props;
  const router = useRouter();
  const auth = useAuth();
  const location = useLocation();

  const redirectLocation =
    !!location.pathname && location.pathname !== paths.dashboard.surveys.list
      ? `?r=${location.pathname}`
      : "";

  useEffect(() => {
    if (!auth.isLoggedIn() && auth.user.is_initialized) {
      router.push(`/auth/login${redirectLocation}`);
    }
  }, [auth.user, router]);

  if (!auth.user.is_initialized) {
    return <GifSplashScreen />;
  }

  return <>{children}</>;
};
