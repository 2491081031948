import {questionFactory} from "src/entities/project/survey/question-factory";

export class Survey {
  constructor(survey) {
    this.id = survey.id;
    this.title = survey.title;
    this.description = survey.description;
    this.language = survey.language;
    this.show_previous_button = survey.show_previous_button;
    this.ip_block = survey.ip_block;
    this.is_active = survey.is_active;
    this.inactive_survey_message = survey.inactive_survey_message;
    this.end_page_message = survey.end_page_message;
    this.is_published = survey.is_published;
    this.flow_id = survey.flow_id;
    this.share_link = survey.share_link;
    if (survey instanceof Survey) {
      this.blocksMap = survey.blocksMap;
      this.pagesMap = survey.pagesMap;
      this.questionsMap = survey.questionsMap;
      this.currentBlock = survey.currentBlock;
      this.currentQuestion = survey.currentQuestion;
    } else {
      this.blocksMap = {}; // blockId, Block map
      this.pagesMap = {}; // pageId, Page map
      this.questionsMap = {}; // questionId, Question map
      this.setSurveyDetails(survey.blocks);
      this.currentBlock = Object.values(this.blocksMap)[0] ?? null;
      this.currentQuestion = Object.values(this.questionsMap)[0] ?? null;
    }
  }

  clone() {
    return new Survey(this);
  }

  setSurveyDetails(blocks) {
    blocks.forEach(block => {
      this.blocksMap[block.id] = new Block(block);

      block.pages.forEach(page => {
        this.pagesMap[page.id] = new Page(page, block.id);

        page.questions.forEach(question => {
          this.questionsMap[question.id] = new Question(question, block.id, page.id);
        });
      });
    });
  }

  setCurrentBlock(block) {
    this.currentBlock = block;
  }

  setCurrentQuestion(question) {
    this.currentQuestion = question;
  }

  setBlock(block) {
    this.blocksMap[block.id] = new Block(block);
  }

  setPage(page, blockId) {
    this.pagesMap[page.id] = new Page(page, blockId);
  }

  setQuestion(question, blockId, pageId) {
    this.questionsMap[question.id] = new Question(question, blockId, pageId);
  }

  removeBlock(blockId) {
    delete this.blocksMap[blockId];
  }

  removePage(pageId) {
    delete this.pagesMap[pageId];
  }

  removeQuestion(questionId) {
    delete this.questionsMap[questionId];
  }

  removeBlockQuestions(blockId) {
    const questionIds = Object.values(this.questionsMap)
      .filter(question => question.blockId === blockId)
      .map(qu => qu.id);
    questionIds.forEach(quId => delete this.questionsMap[quId]);
  }

  getBlockCount() {
    return Object.values(this.blocksMap).length;
  }

  listBlockPages(blockId) {
    return Object.values(this.pagesMap)
      .filter(page => page.blockId === blockId)
      .sort((a, b) => a.index - b.index);
  }

  listBlockQuestions(blockId) {
    return Object.values(this.questionsMap)
      .filter(question => question.blockId === blockId)
      .sort((a, b) => a.index - b.index);
  }

  listPageQuestions(pageId) {
    const questions = Object.values(this.questionsMap)
      .filter(question => question.pageId === pageId)
      .sort((a, b) => a.index - b.index);
    return questions;
  }

  updateSettings(settings) {
    this.language = settings.language;
    this.show_previous_button = settings.show_previous_button;
    this.ip_block = settings.ip_block;
    this.end_page_message = settings.end_page_message;
    this.inactive_survey_message = settings.inactive_survey_message;
    this.share_link = settings.share_link;
  }
}

export class Block {
  constructor(block) {
    this.id = block.id;
    this.title = block.title;
    this.index = block.index;
  }
}

export class Page {
  constructor(page, blockId) {
    this.id = page.id;
    this.index = page.index;
    this.blockId = blockId;
  }
}

export class Question {
  constructor(question, blockId, pageId) {
    this.id = question.id;
    this.reference_id = question.reference_id;
    this.label = question.label;
    this.text = question.text;
    this.type = question.type;
    this.index = question.index;
    this.is_random = question.is_random;
    this.display_logics = question.display_logics;
    this.skip_logics = question.skip_logics;
    this.blockId = blockId;
    this.pageId = pageId;

    Object.assign(this, questionFactory(question));
  }
}
