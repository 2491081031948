import {useParams} from "react-router-dom";
import {Divider, Paper, TableContainer, ToggleButton, Typography} from "@mui/material";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TablePagination from "@mui/material/TablePagination";
import Card from "@mui/material/Card";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {superParticipantService} from "src/services/super/participant";
import {ParticipantGroup} from "src/entities/participant";
import SvgIcon from "@mui/material/SvgIcon";
import UsersUpIcon from "@untitled-ui/icons-react/build/esm/UsersUp";
import IconButton from "@mui/material/IconButton";
import {superParticipantRequestService} from "src/services/super/project/participant-request";
import CircularProgress from "@mui/material/CircularProgress";
import {StyledToggleButtonGroup} from "src/components/styled-toggle-button-group";
import {useAuth} from "src/hooks/use-auth";

export const AddParticipantGroup = props => {
  const {t} = useTranslation();
  const {id} = useParams();
  const {handleNewAssignmentBatch} = props;
  const [participantGroups, setParticipantGroups] = useState([]);
  const [participantGroupCount, setParticipantGroupCount] = useState(0);
  const [participantGroupsTablePage, setParticipantGroupsTablePage] = useState(0);
  const [projectStatus, setProjectStatus] = useState("active");
  const [submitLoading, setSubmitLoading] = useState(null);
  const auth = useAuth();

  const fetchParticipantGroups = page => {
    superParticipantService.listParticipantGroups(page + 1).then(response => {
      setParticipantGroups(response.results.map(res => new ParticipantGroup(res)));
      setParticipantGroupCount(response.count);
      setParticipantGroupsTablePage(page);
    });
  };

  useEffect(() => {
    fetchParticipantGroups(participantGroupsTablePage);
  }, []);

  const handleAssignGroup = groupId => {
    setSubmitLoading(groupId);
    const payload = {
      project_assignment_group_id: groupId,
      status: projectStatus,
      expire_date: null,
    };
    superParticipantRequestService
      .assignProjectAssignmentGroup(id, payload)
      .then(response => {
        handleNewAssignmentBatch(response);
        auth.showAlert("success", t("successfullyAssigned"));
      })
      .catch(_ => {
        auth.showAlert("error", t("errorInAssignment"));
      })
      .finally(_ => {
        setSubmitLoading(null);
      });
  };

  return (
    <>
      <Card elevation={0}>
        <Card sx={{width: "100%", alignItems: "center", px: 4, py: 2, mb: 4}}>
          <Typography variant="h6" component="div" sx={{py: 1}}>
            Project Status
          </Typography>
          <StyledToggleButtonGroup
            size="small"
            value={projectStatus}
            onChange={(e, newStatus) => newStatus && setProjectStatus(newStatus)}
            aria-label="project status alignment"
            exclusive={true}
          >
            <ToggleButton value="soon" aria-label="soon" sx={{px: 2}}>
              <Typography fontSize={13} fontWeight="bold">
                Coming Soon
              </Typography>
            </ToggleButton>

            <Divider flexItem orientation="vertical" sx={{mx: 0.5}} />

            <ToggleButton value="active" aria-label="active" sx={{px: 2}}>
              <Typography fontSize={13} fontWeight="bold">
                Active
              </Typography>
            </ToggleButton>
          </StyledToggleButtonGroup>
        </Card>

        <TableContainer component={Paper} style={{overflow: "auto"}}>
          <Table sx={{minWidth: 1150}}>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>{t("manageParticipantGroups.name")}</TableCell>
                <TableCell>{t("manageParticipantGroups.memberCount")}</TableCell>
                <TableCell>Assign</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {participantGroups.map(group => (
                <TableRow key={group.id}>
                  <TableCell>{group.id}</TableCell>
                  <TableCell>{group.name}</TableCell>
                  <TableCell>{group.member_count}</TableCell>
                  <TableCell>
                    {submitLoading === group.id ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      <IconButton onClick={() => handleAssignGroup(group.id)}>
                        <SvgIcon>
                          <UsersUpIcon />
                        </SvgIcon>
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={participantGroupCount}
          onPageChange={(e, newPage) => fetchParticipantGroups(newPage)}
          onRowsPerPageChange={() => {}}
          page={participantGroupsTablePage}
          rowsPerPage={10}
          // rowsPerPageOptions={[5, 10, 25]}
          rowsPerPageOptions={[10]}
        />
      </Card>
    </>
  );
};
