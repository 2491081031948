import React, {useState} from "react";
import {Box, Button, FormControl, InputBase, Popover, Typography} from "@mui/material";
import {SketchPicker} from "react-color";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import styled from "@emotion/styled";

const StyledTypography = styled(Typography)(({theme}) => ({
  "-webkit-text-size-adjust": "100%",
  "-webkit-font-smoothing": "antialiased",
  boxSizing: "border-box",
  color: "#6C737F",
  lineHeight: "1.4375em",
  fontFamily: `"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"`,
  padding: 0,
  position: "relative",
  fontSize: "14px",
  fontWeight: 500,
  display: "block",
  transformOrigin: "top left",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  maxWidth: "100%",
  transition:
    "color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
}));

export default function ColorPicker({label, color, setColor}) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleColorChange = newColor => {
    setColor(newColor.hex);
  };

  const open = Boolean(anchorEl);
  const id = open ? "color-picker-popover" : undefined;

  return (
    <FormControl fullWidth>
      {label && <StyledTypography>{label}</StyledTypography>}
      <Box
        className="lfe-settings-component-container-input"
        display="flex"
        alignItems="center"
      >
        <Button
          id="color-picker-input"
          type="button"
          aria-haspopup="dialog"
          aria-expanded={open}
          onClick={handleClick}
          aria-labelledby="color-picker-label"
          sx={{
            display: "flex",
            alignItems: "center",
            backgroundColor: "transparent",
            border: "1px solid #ccc",
            padding: "8px",
            minWidth: "50px",
          }}
        >
          <Box
            sx={{
              width: "24px",
              height: "24px",
              backgroundColor: color,
              borderRadius: "4px",
            }}
          />
          <ArrowDropDownIcon />
        </Button>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <SketchPicker color={color} onChangeComplete={handleColorChange} />
        </Popover>
        <Box sx={{display: "flex", alignItems: "center", ml: 2}}>
          <Box component="span" sx={{color: "#ccc", pr: 1}}>
            #
          </Box>
          <InputBase
            aria-label="hex"
            value={color.slice(1)}
            onChange={e => setColor(`#${e.target.value}`)}
            sx={{
              border: "1px solid #ccc",
              padding: "4px 8px",
              borderRadius: "4px",
              fontFamily: "monospace",
              width: "80px",
              textAlign: "center",
            }}
          />
        </Box>
      </Box>
    </FormControl>
  );
}
