import {locatorFactory} from "src/entities/project/condition/locators/locator-factory";
import {expressionLogicType} from "src/schemas/condition/expression-logic-type";
import {expressionLocatorType} from "src/schemas/condition/expression-locatory-type";

export class RewardCondition {
  constructor(condition) {
    this.expressions = condition.expressions.map(expr => new Expression(expr));
  }

  clone() {
    return new RewardCondition(this);
  }

  addExpression(expressionIndex) {
    const defaultExpression = {
      expression_type: expressionLogicType.QUESTION,
      locator_type: null,
    };
    this.expressions.splice(expressionIndex + 1, 0, new Expression(defaultExpression));
  }

  deleteExpression(expressionIndex) {
    this.expressions.splice(expressionIndex, 1);
  }
}

export class Expression {
  constructor(expression) {
    if (expression.locator_type === expressionLocatorType.EMBEDDED_DATA) {
      this.expression_type = expressionLogicType.EMBEDDED_DATA;
    } else if (expression.locator_type === expressionLocatorType.DURATION) {
      this.expression_type = expressionLogicType.DURATION;
    } else {
      this.expression_type = expressionLogicType.QUESTION;
    }
    this.locator_type = expression.locator_type;
    this.locator = locatorFactory(expression.locator_type, expression.locator);
  }

  setLogicType(name, value) {
    Object.assign(this, {[name]: value});
    if (name === "expression_type") {
      this.expression_type = value;
      if (value === expressionLogicType.QUESTION) {
        this.locator_type = null;
        this.locator = null;
      } else if (value === expressionLogicType.EMBEDDED_DATA) {
        this.locator_type = expressionLogicType.EMBEDDED_DATA;
        this.locator = locatorFactory(this.expression_type, {});
      } else if (value === expressionLogicType.DURATION) {
        this.locator_type = expressionLogicType.DURATION;
        this.locator = locatorFactory(this.expression_type, {});
      }
    }
  }

  setQuestionLocator(question) {
    const loc = {
      left_operand: question.reference_id,
    };
    this.locator_type = question.type;
    this.locator = locatorFactory(this.locator_type, loc);
  }
}
