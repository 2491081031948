import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import {useEffect} from "react";
import {useTranslation} from "react-i18next";

const operands = [
  {
    label: "isDisplayed",
    value: "is_displayed",
  },
  {
    label: "isNotEqual",
    value: "is_not_equal",
  },
];

export function textGraphicExpressionText(locator, question) {
  return `${question.label} ${locator.operand}`;
}

export const TextGraphicLocator = props => {
  const {index, question, locator, handleLocator} = props;
  const {t} = useTranslation();

  useEffect(() => {
    if (locator.left_operand !== question.reference_id) {
      handleLocator(index, "operand", null);
    }
  }, [question.id]);

  const handleValue = e => {
    const name = e.target.name;
    const value = e.target.value;
    handleLocator(index, name, value);
  };

  return (
    <>
      <Grid sx={{pl: 1}} item xs={5}>
        <TextField
          label={t("selectOperand")}
          name="operand"
          select
          value={locator.operand}
          onChange={handleValue}
          sx={{px: 0, width: "100%"}}
        >
          {operands.map((operand, index) => (
            <MenuItem key={index} value={operand.value}>
              {t(operand.label)}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
    </>
  );
};
