import {expressionLocatorType} from "src/schemas/condition/expression-locatory-type";
import {MultipleChoiceLocator} from "src/entities/project/condition/locators/multiple-choice-locator";
import {TextGraphicLocator} from "src/entities/project/condition/locators/text-graphic-locator";
import {ShortTextLocator} from "src/entities/project/condition/locators/short-text-locator";
import {EmbeddedDataLocator} from "src/entities/project/condition/locators/embedded-data-locator";
import {DurationLocator} from "src/entities/project/condition/locators/duration-locator";

export function locatorFactory(locatorType, locator) {
  switch (locatorType) {
    case expressionLocatorType.MULTIPLE_CHOICE:
      return new MultipleChoiceLocator(locator);
    case expressionLocatorType.SHORT_TEXT:
      return new ShortTextLocator(locator);
    case expressionLocatorType.TEXT_GRAPHIC:
      return new TextGraphicLocator(locator);
    case expressionLocatorType.EMBEDDED_DATA:
      return new EmbeddedDataLocator(locator);
    case expressionLocatorType.DURATION:
      return new DurationLocator(locator);
    default:
      return null;
  }
}
