import React, {useState} from "react";
import Button from "@mui/material/Button";
import {ButtonBase, Card, Grid, IconButton, TextField, Tooltip} from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import {LoadingButton} from "@mui/lab";
import * as XLSX from "xlsx";
import {useTranslation} from "react-i18next";
import {questionType, questionTypeMap} from "src/schemas/survey/question/question-types";
import {
  multipleChoiceQuestionFormat,
  multipleChoiceQuestionFormatMap,
} from "src/schemas/survey/question/multiple-choice-question-settings";
import {projectService} from "src/services/project/project";
import {paths} from "src/pages/paths";
import {useRouter} from "src/hooks/use-router";
import {useAuth} from "src/hooks/use-auth";
import {DownloadExcelButton} from "src/pages/admin/surveys/buttons/download-excel-button.js";
import {Stack} from "@mui/system";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import TaskOutlinedIcon from "@mui/icons-material/TaskOutlined";

const validateQuestionData = data => {
  const questionData = {};

  const inputQuestionType = questionTypeMap.find(
    el => el.label === data["Question Type"],
  );
  if (!inputQuestionType) {
    return false;
  }
  questionData["questionType"] = inputQuestionType.value;
  if (questionData.questionType === questionType.MultipleChoice) {
    const format = multipleChoiceQuestionFormatMap.find(
      el => el.label === data["Multiple Choice Option Format"],
    );
    questionData["format"] = format ? format.value : multipleChoiceQuestionFormat.list;
    const options = data["Multiple Choice Options"];
    questionData["options"] = options ? options.split("\n") : [];
    questionData["is_options_random"] =
      data["Multiple Choice Options Randomized"] ?? false;
    questionData["allow_multiple_answers"] = data["Allow Multiple Answers"] ?? false;
  }
  questionData["label"] = data["Question Label"] ?? "Unknown Question Label";
  questionData["title"] =
    data["Question Text"].replace(/\n/g, "<br/>") ?? "Unknown Question Title";
  questionData["isRequired"] = data["Required"] ?? false;
  return questionData;
};

const buildQuestion = data => {
  let settings = {};
  if (data.questionType === questionType.MultipleChoice) {
    settings = {
      is_required: data.isRequired,
      format: data.format,
      allow_multiple_answers: data.allow_multiple_answers,
      options: data.options.map(option => ({
        id: null,
        text: option.length === 0 ? "Empty Option" : option,
        is_random: data.is_options_random,
      })),
    };
  } else {
    settings = {
      is_required: data.isRequired,
    };
  }
  return {
    id: null,
    label: data.label,
    text: data.title,
    type: data.questionType,
    is_random: false,
    ...settings,
  };
};

const ExcellToSurvey = () => {
  const {t, i18n} = useTranslation();
  const router = useRouter();
  const [surveyName, setSurveyName] = useState("");
  const [blocks, setBlocks] = useState(null);
  const [isImporting, setIsImporting] = useState(false);
  const auth = useAuth();
  const [uploadMessage, setUploadMessage] = useState("");

  const handleClickUploadExcelFile = e => {
    const file = e.target.files[0];
    setUploadMessage(`${file.name} ${t("hasBeenUploaded")}`);
    const reader = new FileReader();

    reader.onload = event => {
      const data = event.target.result;
      const workbook = XLSX.read(data, {type: "binary"});

      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];

      const jsonData = XLSX.utils.sheet_to_json(sheet);
      // Process the JSON data as needed
      console.log(jsonData); // Example: just log it
      setBlocks(jsonData); // Assuming setBlocks is to store processed data
      if (Array.isArray(jsonData)) {
        const groupedData = jsonData.reduce((acc, obj) => {
          const key = obj["Question Group Name"];
          if (!acc[key]) {
            acc[key] = [];
          }
          acc[key].push(obj);
          return acc;
        }, {});

        const invalidQuestions = [];
        const result = Object.entries(groupedData).map(
          ([blockName, questions], block_index) => {
            const blockQuestions = questions.reduce((acc, obj, index) => {
              const validatedQuestionData = validateQuestionData(obj);
              if (validatedQuestionData) {
                const question = buildQuestion(validatedQuestionData);
                acc.push(question);
              } else {
                invalidQuestions.push(index + 1);
              }
              return acc;
            }, []);

            return {
              title: blockName,
              pages: [
                {
                  id: null,
                  questions: blockQuestions,
                },
              ],
            };
          },
        );
        if (invalidQuestions.length) {
          const invalidQuestionRows = invalidQuestions.join(", ");
          auth.showAlert(t("error"), `${t("importExcelError")}  ${invalidQuestionRows}.`);
        }
        console.log(result);
        setBlocks(result);
      }
    };
    reader.readAsBinaryString(file);
  };

  const importFile = () => {
    setIsImporting(true);
    const surveyData = {
      title: surveyName,
      blocks: blocks,
    };
    projectService.createFromExcel(surveyData).then(survey => {
      router.push(paths.surveyBuilder.edit.replace(":uuid", survey.id));
      setIsImporting(false);
    });
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        py: 4,
      }}
    >
      <Container
        maxWidth="xl"
        sx={{
          direction: i18n.language === "ar" ? "rtl" : "ltr",
        }}
      >
        <Typography variant="h4">{t("importExcelDialogTitle")}</Typography>

        <Box
          component="main"
          sx={{
            flexGrow: 1,
            py: 4,
            m: 4,
          }}
        >
          <Container maxWidth="lg">
            <Stack spacing={4} alignItems="center">
              <Stack
                direction={{xs: "column", md: "row"}}
                spacing={8}
                alignItems="center"
                justifyContent="center"
              >
                <Box textAlign="center">
                  <img src="/assets/svg/numberone.svg" alt="Step 1" />
                  <Typography variant="h6" color={"#000066"} sx={{mt: 1}}>
                    {t("downloadExcelFormat")}
                  </Typography>
                </Box>

                <Box>
                  <img src="/assets/svg/firstArrow.svg" alt="First Arrow" />
                </Box>

                <Box textAlign="center">
                  <img src="/assets/svg/numbertwo.svg" alt="Step 2" />
                  <Typography variant="h6" color={"#000066"} sx={{mt: 1}}>
                    {t("writeToExcelFormat")}
                  </Typography>
                </Box>

                <Box>
                  <img src="/assets/svg/secondArrow.svg" alt="Second Arrow" />
                </Box>

                <Box textAlign="center">
                  <img src="/assets/svg/numberthree.svg" alt="Step 3" />
                  <Typography variant="h6" color={"#000066"} sx={{mt: 1}}>
                    {t("downloadExcelFormat")}
                  </Typography>
                </Box>
              </Stack>
            </Stack>
          </Container>
        </Box>
        <DownloadExcelButton />

        <Card
          sx={{
            width: "100%",
            alignItems: "center",
            px: uploadMessage ? 4 : 0,
            py: uploadMessage ? 2 : 0,
            my: uploadMessage ? 4 : 0,
          }}
        >
          <Tooltip title={t("buttons.UploadDraftExcel")} arrow>
            <Card
              component={ButtonBase} // Makes the entire card clickable
              sx={{
                minHeight: 180,
                display: "flex",
                width: "100%",
                alignItems: "center",
                px: 4,
                py: 2,
                // my: 4,
                transition: "box-shadow 0.2s", // Adds smooth shadow transition
                "&:hover": {
                  boxShadow: 3, // Shadow on hover
                },
                "&:active": {
                  boxShadow: 6, // Deeper shadow when clicked
                },
              }}
              onClick={() => {
                document.getElementById("file-upload").click();
              }}
            >
              <img src="/assets/svg/galataanketgraylogo.svg" alt="Logo" />

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  flexGrow: 1,
                  ml: 2,
                  p: 2,
                  textAlign: "left",
                }}
              >
                <Typography variant="h6" component="div" sx={{py: 1}}>
                  {t("importFromExcelFile")}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {t("importExcelDirective")}
                </Typography>
              </Box>

              <IconButton disabled aria-label="upload">
                <FileUploadOutlinedIcon fontSize="large" />
              </IconButton>
              <input
                id="file-upload"
                type="file"
                accept=".xlsx, .xls"
                hidden
                onChange={handleClickUploadExcelFile}
              />
            </Card>
          </Tooltip>

          {uploadMessage && (
            <>
              <Box
                sx={{
                  my: 4,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <TaskOutlinedIcon fontSize="large" sx={{color: "green", mr: 2}} />
                <Typography align="center" variant="body1" sx={{color: "green"}}>
                  {uploadMessage}
                </Typography>
              </Box>

              <Typography align="center">
                <Grid containerspacing={3}>
                  <Grid item xs={8}>
                    <TextField
                      sx={{mt: 1}}
                      fullWidth
                      label={t("importExcelNameLabel")}
                      value={surveyName}
                      onChange={e => setSurveyName(e.target.value)}
                    />
                  </Grid>
                </Grid>
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mt: 3,
                }}
              >
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    setUploadMessage("");
                    setSurveyName("");
                    setBlocks(null);
                    setIsImporting(false);
                  }}
                  sx={{
                    width: "48%",
                    fontWeight: "bold",
                  }}
                >
                  {t("cancel")}
                </Button>

                <LoadingButton
                  fullWidth
                  loading={isImporting}
                  disabled={!surveyName || !blocks}
                  size="large"
                  type="submit"
                  variant="contained"
                  onClick={importFile}
                  sx={{
                    width: "48%",
                    fontWeight: "bold",
                    backgroundColor: "primary.main",
                    "&:hover": {
                      backgroundColor: "primary.dark",
                    },
                  }}
                >
                  {t("importFileButtonLabel")}
                </LoadingButton>
              </Box>
            </>
          )}
        </Card>
      </Container>
    </Box>
  );
};

export default ExcellToSurvey;
