import * as Yup from "yup";
import "dayjs/locale/tr";
import {useFormik} from "formik";
import {useRouter} from "src/hooks/use-router";
import {Stack} from "@mui/system";
import Typography from "@mui/material/Typography";
import {
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Link,
  TextField,
} from "@mui/material";
import Button from "@mui/material/Button";
import React, {useState} from "react";
import CircularProgress from "@mui/material/CircularProgress";
import {useParams} from "react-router-dom";
import {DatePicker} from "@mui/x-date-pickers";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import MenuItem from "@mui/material/MenuItem";
import {newauthService} from "src/new_services/auth";
import {paths} from "src/pages/paths";
import {format} from "date-fns";
import Box from "@mui/material/Box";
import {Visibility, VisibilityOff} from "@mui/icons-material";

const initialValues = {
  username: "",
  birthDate: null,
  gender: null,
  notifications: false,
  password: "",
  confirmPassword: "",
  submit: null,
};

const validationSchema = Yup.object({
  username: Yup.string().max(255).required("Bu kısmın doldurulması gereklidir."),
  birthDate: Yup.date().required("Bu kısmın doldurulması gereklidir."),
  gender: Yup.string().max(255).required("Bu kısmın doldurulması gereklidir."),
  notifications: Yup.bool().notRequired(),
  password: Yup.string().max(255).required("Bu kısmın doldurulması gereklidir."),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Şifreler eşleşmelidir.")
    .required("Bu kısmın doldurulması gereklidir."),
});

export const UserActivation = () => {
  const router = useRouter();
  const {activationToken} = useParams();
  const [loading, setLoading] = useState(false);
  const [terms, setTerms] = useState(false);
  const [privacy, setPrivacy] = useState(false);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values, helpers) => {
      try {
        setLoading(true);
        const payload = {
          username: values.username,
          birth_date: `${format(values.birthDate.$d, "yyyy-MM-dd")}`,
          gender: values.gender,
          notifications: values.notifications,
          password: values.password,
          token: activationToken,
        };
        newauthService.activateUser(payload).then(response => {
          router.push(paths.auth.login);
          setLoading(false);
          helpers.setSubmitting(false);
        });
      } catch (err) {
        setLoading(false);
        helpers.setSubmitting(false);
        console.error(err);
      }
    },
  });

  const testFunction = val => {
    if (formik.values.birthDate === null) {
      formik.setErrors({birthDate: "Bu kısmın doldurulması gereklidir."});
    }
  };

  return (
    <>
      <Stack sx={{mb: 4}} spacing={1}>
        <Typography variant="h5">Aktivasyon</Typography>
      </Stack>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"tr"}>
        <form noValidate onSubmit={formik.handleSubmit}>
          <Stack spacing={3}>
            <TextField
              error={!!(formik.touched.username && formik.errors.username)}
              helperText={formik.touched.username && formik.errors.username}
              fullWidth
              label="Kullanıcı İsmi"
              name="username"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.username}
            />
            <DatePicker
              label={"Doğum Tarihi"}
              onChange={value => formik.setFieldValue("birthDate", value, true)}
              disableFuture={true}
              onSelectedSectionsChange={testFunction}
              slotProps={{
                textField: {
                  error: Boolean(formik.errors.birthDate),
                  helperText: Boolean(formik.errors.birthDate)
                    ? "Bu kısmın doldurulması gereklidir."
                    : "",
                },
              }}
            />
            <TextField
              error={!!(formik.touched.gender && formik.errors.gender)}
              fullWidth
              helperText={formik.touched.gender && formik.errors.gender}
              label="Cinsiyet"
              name="gender"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.gender}
              select
            >
              {["Male", "Female", "Other"].map(gender => (
                <MenuItem key={1} value={gender}>
                  {gender}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              error={!!(formik.touched.password && formik.errors.password)}
              fullWidth
              helperText={formik.touched.password && formik.errors.password}
              label="Şifre"
              name="password"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type={formik.values.showPassword ? "text" : "password"}
              value={formik.values.password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() =>
                        formik.setFieldValue("showPassword", !formik.values.showPassword)
                      }
                      onMouseDown={e => e.preventDefault()}
                      edge="end"
                    >
                      {formik.values.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              error={!!(formik.touched.confirmPassword && formik.errors.confirmPassword)}
              fullWidth
              helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
              label="Şifreyi Doğrula"
              name="confirmPassword"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type={formik.values.showPassword ? "text" : "password"}
              value={formik.values.confirmPassword}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() =>
                        formik.setFieldValue("showPassword", !formik.values.showPassword)
                      }
                      onMouseDown={e => e.preventDefault()}
                      edge="end"
                    >
                      {formik.values.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
          <Box sx={{mt: 3}}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formik.values.notifications}
                  onChange={formik.handleChange}
                  name="notifications"
                  color="primary"
                />
              }
              label={
                <Typography variant="body2">
                  Yeni anketlerden haberdar olmak isterim.
                </Typography>
              }
            />
            <br />
            Sizleri anket sistemimize dahil edip anketler ve/ya ödüller için haberdar
            edebilmemiz için aşağıdaki metinleri onaylamanız gerekmektedir.
            <FormControlLabel
              control={
                <Checkbox
                  checked={privacy}
                  onChange={_ => setPrivacy(!privacy)}
                  name="agreement"
                  color="primary"
                />
              }
              label={
                <Typography variant="body2">
                  <Link
                    href="/terms_conditions_all/Galata Anket - İnternet Sitesi Aydınlatma Metni.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Aydınlatma metnini
                  </Link>{" "}
                  okudum ve kabul ediyorum.
                </Typography>
              }
            />
          </Box>
          <Box sx={{mt: 0}}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={terms}
                  onChange={_ => setTerms(!terms)}
                  name="agreement"
                  color="primary"
                />
              }
              label={
                <Typography variant="body2">
                  <Link
                    href="/terms_conditions_all/Galata Anket - İnternet Sitesi Gizlilik ve Çerez Politikası.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Gizlilik ve Çerez Politikasını
                  </Link>{" "}
                  okudum ve kabul ediyorum.
                </Typography>
              }
            />
          </Box>
          <Button
            fullWidth
            disabled={terms && privacy ? formik.isSubmitting : true}
            sx={{mt: 3}}
            size="large"
            type="submit"
            variant="contained"
          >
            {loading ? <CircularProgress size={24} color="inherit" /> : "Kayıt ol"}
          </Button>
        </form>
      </LocalizationProvider>
    </>
  );
};
