import React from "react";
import {
  TextField,
  Button,
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  CircularProgress,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import {useFormik} from "formik";
import * as Yup from "yup";
import emailjs from "emailjs-com";
import {useSettings} from "src/hooks/use-settings";
import {useTranslation} from "react-i18next";

const ContactForm = () => {
  const {t} = useTranslation();
  const settings = useSettings();

  const formik = useFormik({
    initialValues: {
      name: "",
      surname: "",
      email: "",
      message: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required(t("RequiredLabel")),
      surname: Yup.string().required(t("RequiredLabel")),
      email: Yup.string().email(t("mustBeValidEmail")).required(t("RequiredLabel")),
      message: Yup.string().required(t("RequiredLabel")),
    }),
    onSubmit: (values, {setSubmitting, resetForm}) => {
      emailjs
        .send("service_exohkma", "template_30tdzvu", values, "A2pgTFc93Cu0aXiC3")
        .then(result => {
          settings.showAlert("success", t("messageSent"));
          resetForm();
        })
        .catch(error => {
          settings.showAlert("error", t("messageSendError"));
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  return (
    <Box
      sx={{
        minHeight: "80svh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        p: 3,
      }}
    >
      <Card sx={{width: "100%", maxWidth: 1200, boxShadow: 3}}>
        <CardContent>
          <Grid container spacing={3} alignItems="center">
            {/* Left side - Logo and message */}
            <Grid
              item
              xs={12}
              md={6}
              container
              direction="column"
              alignItems="center"
              justifyContent="center"
              sx={{textAlign: {xs: "center", md: "left"}}}
            >
              <Box sx={{mb: 3}}>
                <img
                  src="/logo.png"
                  alt="Logo"
                  style={{width: "150px", marginBottom: "16px"}}
                />
                {/* Replace with your logo */}
                <Typography variant="h5" color="primary" sx={{fontWeight: "bold", mb: 1}}>
                  {t("welcomeContactPage")}
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  {t("contactInvitation")}
                </Typography>
              </Box>
            </Grid>

            {/* Right side - Contact Form */}
            <Grid
              item
              xs={12}
              md={6}
              container
              direction="column"
              alignItems="center"
              justifyContent="center"
            >
              <Typography
                variant="h4"
                align="center"
                gutterBottom
                sx={{fontWeight: "bold", color: "primary.main"}}
              >
                {t("contactUs")}
              </Typography>
              <form
                onSubmit={formik.handleSubmit}
                style={{width: "100%", maxWidth: "400px"}}
              >
                <TextField
                  label={t("name")}
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  fullWidth
                  margin="normal"
                  required
                  variant="outlined"
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                  sx={{
                    "& .MuiInputLabel-root": {color: "text.secondary"},
                    "& .MuiOutlinedInput-root": {borderRadius: "8px"},
                  }}
                />
                <TextField
                  label={t("surname")}
                  name="surname"
                  value={formik.values.surname}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  fullWidth
                  margin="normal"
                  required
                  variant="outlined"
                  error={formik.touched.surname && Boolean(formik.errors.surname)}
                  helperText={formik.touched.surname && formik.errors.surname}
                  sx={{
                    "& .MuiInputLabel-root": {color: "text.secondary"},
                    "& .MuiOutlinedInput-root": {borderRadius: "8px"},
                  }}
                />
                <TextField
                  label={t("mail")}
                  name="email"
                  type="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  fullWidth
                  margin="normal"
                  required
                  variant="outlined"
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  sx={{
                    "& .MuiInputLabel-root": {color: "text.secondary"},
                    "& .MuiOutlinedInput-root": {borderRadius: "8px"},
                  }}
                />
                <TextField
                  label={t("message")}
                  name="message"
                  value={formik.values.message}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  fullWidth
                  margin="normal"
                  multiline
                  rows={4}
                  required
                  variant="outlined"
                  error={formik.touched.message && Boolean(formik.errors.message)}
                  helperText={formik.touched.message && formik.errors.message}
                  sx={{
                    "& .MuiInputLabel-root": {color: "text.secondary"},
                    "& .MuiOutlinedInput-root": {borderRadius: "8px"},
                  }}
                />
                <Button
                  type={t("submit")}
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={formik.isSubmitting}
                  endIcon={
                    formik.isSubmitting ? <CircularProgress size={20} /> : <SendIcon />
                  }
                  sx={{mt: 2, py: 1.5, fontWeight: "bold", textTransform: "none"}}
                >
                  {formik.isSubmitting ? t("sending") : t("sendMessage")}
                </Button>
              </form>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};

export default ContactForm;
