import {Container} from "@mui/system";
import {useState} from "react";
import {Box, Tab, Tabs} from "@mui/material";
import {CreateRewardProject} from "src/pages/dashboard/super/manage-participant-rewards/create-reward-project";
import {AssignRewardProject} from "src/pages/dashboard/super/manage-participant-rewards/assign-reward-project";

const CustomTabPanel = props => {
  const {children, value, index} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{width: "100%"}}
    >
      {value === index && <>{children}</>}
    </div>
  );
};

export const ManageParticipantRewards = () => {
  const [activeTab, setActiveTab] = useState("create");

  return (
    <Container maxWidth="lg">
      <Box sx={{borderBottom: 1, borderColor: "divider", mb: 2}}>
        <Tabs value={activeTab} onChange={(e, newVal) => setActiveTab(newVal)}>
          <Tab label={"Create Reward Project"} value="create" />
          <Tab label={"Assign Reward Project"} value="assign" />
        </Tabs>
      </Box>

      <CustomTabPanel value={activeTab} index="create">
        <CreateRewardProject />
      </CustomTabPanel>

      <CustomTabPanel value={activeTab} index="assign">
        <AssignRewardProject />
      </CustomTabPanel>
    </Container>
  );
};
