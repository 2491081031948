import {useTranslation} from "react-i18next";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import {usePopover} from "src/hooks/use-popover";
import {ListItemIcon, ListItemText, MenuItem, Popover} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useCallback} from "react";

const languageOptions = {
  en: {
    icon: "/assets/flags/english.png",
    label: "English",
  },
  tr: {
    icon: "/assets/flags/turkish.png",
    label: "Turkish",
  },
  // ar: {
  //   icon: "/assets/flags/arabic.png",
  //   label: "Arabic",
  // },
};

export const LanguageSwitchButton = () => {
  const {i18n, t} = useTranslation();
  const popover = usePopover();
  const flag = languageOptions[i18n.language].icon;

  const handleChange = useCallback(
    async language => {
      popover.handleClose();
      localStorage.setItem("language", language);
      await i18n.changeLanguage(language);
    },
    [i18n, t],
  );

  return (
    <>
      <Tooltip title="Language">
        <IconButton onClick={popover.handleOpen} ref={popover.anchorRef}>
          <Box
            sx={{
              width: 28,
              "& img": {
                width: "100%",
              },
            }}
          >
            <img src={flag} />
          </Box>
        </IconButton>
      </Tooltip>
      <Popover
        anchorEl={popover.anchorRef.current}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
        disableScrollLock
        transformOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        onClose={popover.handleClose}
        open={popover.open}
      >
        {Object.keys(languageOptions).map(language => {
          const option = languageOptions[language];

          return (
            <MenuItem onClick={() => handleChange(language)} key={language}>
              <ListItemIcon>
                <Box
                  sx={{
                    width: 28,
                    "& img": {
                      width: "100%",
                    },
                  }}
                >
                  <img alt={option.label} src={option.icon} />
                </Box>
              </ListItemIcon>
              <ListItemText
                primary={<Typography variant="subtitle2">{option.label}</Typography>}
              />
            </MenuItem>
          );
        })}
      </Popover>
    </>
  );
};
