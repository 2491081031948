export const disapledHandleEnterKeyDown = event => {
  if (event.key === "Enter") {
    event.preventDefault();
  }
};

export function getDistributionUrl() {
  if (process.env.REACT_APP_ENV === "demo") {
    return "https://dev.participant.anketekatil.com";
  } else {
    return "https://galataanket.anketekatil.com";
  }
}

export function calculateAge(birthDate) {
  const today = new Date();
  const birthDateObj = new Date(birthDate);

  let age = today.getFullYear() - birthDateObj.getFullYear();
  const monthDifference = today.getMonth() - birthDateObj.getMonth();

  if (
    monthDifference < 0 ||
    (monthDifference === 0 && today.getDate() < birthDateObj.getDate())
  ) {
    age--;
  }

  return age;
}
