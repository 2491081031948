import {Container} from "@mui/system";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import {useEffect, useState, useMemo} from "react";
import {ImportExcelButton} from "src/pages/admin/surveys/buttons/import-excel-button";
import {CreateSurveyButton} from "src/pages/admin/surveys/buttons/create-survey-button";
import useMediaQuery from "@mui/material/useMediaQuery";
import {Grid} from "@mui/material";
import {GifSplashScreen} from "src/components/gif-splash-screen";
import {useTranslation} from "react-i18next";

export const NewSurvey = () => {
  const {t} = useTranslation();

  const surveyData = useMemo(
    () => [
      {
        title: t("newSurveys.demographicPoll"),
        description: t("newSurveys.customizeDraft"),
      },
      {
        title: t("newSurveys.customerFeedback"),
        description: t("newSurveys.customizeDraft"),
      },
      {
        title: t("newSurveys.employeeFeedback"),
        description: t("newSurveys.customizeDraft"),
      },
    ],
    [t],
  );

  const fromScratchData = useMemo(
    () => [
      {
        title: t("newSurveys.newSurveyFromScratch"),
        description: t("newSurveys.newSurveyFromScratchDescription"),
        fromScratch: true,
      },
      {
        title: t("newSurveys.newSurveyFromExcel"),
        description: t("newSurveys.uploadFromExcel"),
        fromExcel: true,
      },
    ],
    [t],
  );

  const [surveys, setSurveys] = useState(null);
  const [fromScratch, setFromScratch] = useState(null);

  const isMobile = useMediaQuery("(max-width:600px)");

  useEffect(() => {
    // Simulate data fetching
    setTimeout(() => {
      setSurveys(surveyData);
      setFromScratch(fromScratchData);
    }, 1000);
  }, [surveyData, fromScratchData]);

  if (surveys === null || fromScratch === null) {
    return <GifSplashScreen />;
  }

  // ... Rest of the component remains the same
  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 4,
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={4}>
            {/* Most Popular Templates Section */}
            <Typography variant="h4">{t("newSurveys.createNewSurveyTitle")}</Typography>
            {/* <Typography variant="h6">{t("newSurveys.createNewSurveyDescription")}</Typography> */}
            <Box
              display="flex"
              justifyContent="center"
              flexWrap={isMobile ? "nowrap" : "wrap"}
              flexDirection={isMobile ? "column" : "row"}
              gap={2}
            >
              <Grid container spacing={3} justifyContent="center">
                {fromScratch.map((survey, index) => (
                  <Grid
                    item
                    xs={12} // 1 card per row on small screens (xs)
                    sm={6} // 2 cards per row on medium screens (sm)
                    lg={6} // 3 cards per row on large screens (lg)
                    key={index}
                  >
                    <Card
                      sx={{
                        height: "180px",
                        padding: 2,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        position: "relative",
                        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                        transition:
                          "box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out",
                        borderRadius: "12px",
                        backgroundColor: "#f5f5f5",
                        border: "1px solid #ccc",
                        "&:hover": {
                          boxShadow: "0px 8px 24px rgba(0, 0, 0, 0.2)",
                          borderColor: "#ad1445",
                          transform: "translateY(-4px)",
                        },
                      }}
                    >
                      <Box mb={2}>
                        <Typography variant="h6" gutterBottom>
                          {survey.title}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          {survey.description}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginTop: "auto",
                        }}
                      >
                        <img
                          src="/favicon.ico"
                          alt="Logo"
                          style={{width: 40, height: 40, borderRadius: "50%"}}
                        />
                        {survey.fromScratch ? (
                          <CreateSurveyButton />
                        ) : (
                          <ImportExcelButton />
                        )}
                      </Box>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Box>

            {/* From Scratch Section */}
            <Typography variant="h6">
              {t("newSurveys.selectFromPopularTemplates")}
            </Typography>
            <Box display="flex" justifyContent="center" flexWrap="wrap" gap={2}>
              <Grid container spacing={3} justifyContent="center">
                {surveys.map((survey, index) => (
                  <Grid
                    item
                    xs={12} // 1 kart küçük ekranlarda
                    sm={6} // 2 kart orta ekranlarda
                    lg={4} // 3 kart büyük ekranlarda
                    key={index}
                  >
                    <Card
                      sx={{
                        height: "200px",
                        padding: 2,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        position: "relative",
                        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                        transition:
                          "box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out",
                        borderRadius: "12px",
                        backgroundColor: "#f5f5f5",
                        border: "1px solid #ccc",
                        "&:hover": {
                          boxShadow: "0px 8px 24px rgba(0, 0, 0, 0.2)",
                          borderColor: "#ad1445",
                          transform: "translateY(-4px)",
                        },
                      }}
                    >
                      <Box mb={2}>
                        <Typography variant="h6" gutterBottom>
                          {survey.title}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          {survey.description}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginTop: "auto",
                        }}
                      >
                        <img
                          src="/favicon.ico"
                          alt="Logo"
                          style={{width: 40, height: 40, borderRadius: "50%"}}
                        />
                        <Button
                          variant="contained"
                          size="small"
                          sx={{
                            backgroundColor: "black",
                            color: "white",
                            "&:hover": {
                              backgroundColor: "black",
                            },
                          }}
                        >
                          {t("newSurveys.startSurvey")}
                        </Button>
                      </Box>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Stack>
        </Container>
      </Box>
    </>
  );
};
