import {useTranslation} from "react-i18next";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import {useEffect, useState} from "react";
import {getText} from "src/utils/get-text";
import {questionType} from "src/schemas/survey/question/question-types";
import {MultipleChoiceLocator} from "src/components/expression/logics/question-locators/multiple-choice-locator";
import {ShortTextLocator} from "src/components/expression/logics/question-locators/short-text-locator";
import {TextGraphicLocator} from "src/components/expression/logics/question-locators/text-graphic-locator";

const QuestionLocator = props => {
  const {index, question, locator, handleLocator} = props;
  switch (question.type) {
    case questionType.MultipleChoice:
      return (
        <MultipleChoiceLocator
          index={index}
          question={question}
          locator={locator}
          handleLocator={handleLocator}
        />
      );
    case questionType.ShortText:
      return (
        <ShortTextLocator
          index={index}
          question={question}
          locator={locator}
          handleLocator={handleLocator}
        />
      );
    case questionType.TextGraphic:
      return (
        <TextGraphicLocator
          index={index}
          question={question}
          locator={locator}
          handleLocator={handleLocator}
        />
      );
  }
};

export const QuestionLogic = props => {
  const {index, questions, expression, handleLocator} = props;
  const {t} = useTranslation();
  const [selectedQuestion, setSelectedQuestion] = useState(null);

  useEffect(() => {
    if (expression.locator) {
      const question = questions.find(
        question => question.reference_id === expression.locator.left_operand,
      );
      setSelectedQuestion(question);
      // handleLocator(index, "left_operand", question.reference_id);
    }
  }, []);

  const handleSelectedQuestion = e => {
    const value = e.target.value;
    setSelectedQuestion(value);
    handleLocator(index, "left_operand", value);
  };

  if (expression.locator && selectedQuestion === null) {
    return null;
  }

  return (
    <>
      <Grid sx={{pl: 1}} item xs={3}>
        <TextField
          // defaultValue={question.type}
          label={t("blockSettings.selectAQuestion")}
          name="question"
          select
          value={selectedQuestion}
          onChange={handleSelectedQuestion}
          sx={{px: 0, width: "100%"}}
        >
          {questions.map(quest => (
            <MenuItem key={quest.id} value={quest}>
              {`${quest.label}. ${getText(quest.text)}`}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      {selectedQuestion && (
        <QuestionLocator
          index={index}
          question={selectedQuestion}
          locator={expression.locator}
          handleLocator={handleLocator}
        />
      )}
    </>
  );
};
