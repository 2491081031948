import {flowElementType} from "src/schemas/survey/flow/flow-element-types";

export class Flow {
  constructor(flow) {
    this.id = flow.id;
    this.elements = flow.elements.map(element => new FlowElement(element));
  }
}

export class FlowElement {
  constructor(flowElement) {
    this.id = flowElement.id;
    this.index = flowElement.index;
    this.type = flowElement.type;
    if (this.type === flowElementType.BLOCK) {
      this.element = new BlockElement(flowElement.element);
    } else if (this.type === flowElementType.BRANCH) {
      this.element = new BranchElement(flowElement.element);
    } else if (this.type === flowElementType.RANDOMIZER) {
      this.element = new RandomizerElement(flowElement.element);
    }
  }
}

export class BlockElement {
  constructor(element) {
    this.id = element.id;
    this.title = element.title;
  }
}

export class BranchElement {
  constructor(element) {
    this.flow = new Flow(element.flow);
    this.conditions = element.conditions;
  }
}

export class RandomizerElement {
  constructor(element) {
    this.flow = new Flow(element.flow);
    this.num_blocks_to_show = element.num_blocks_to_show;
  }
}
