import React from "react";
import SvgIcon from "@mui/material/SvgIcon";
import Upload01Icon from "@untitled-ui/icons-react/build/esm/Upload01";
import Button from "@mui/material/Button";
import {useTranslation} from "react-i18next";
import {useRouter} from "src/hooks/use-router";

export const ImportExcelButton = () => {
  const router = useRouter();
  const {t, i18n} = useTranslation();

  return (
    <>
      <Button
        onClick={() => {
          router.push(`/dashboard/excelltosurvey`);
        }}
        startIcon={
          <SvgIcon>
            <Upload01Icon />
          </SvgIcon>
        }
        variant="contained"
        sx={{direction: i18n.language === "ar" ? "rtl" : "ltr"}}
      >
        {t("importExcelButtonLabel")}
      </Button>
    </>
  );
};
