import {expressionLogicType} from "src/schemas/condition/expression-logic-type";
import {QuestionLogic} from "src/components/expression/logics/question-logic";
import {EmbeddedDataLogic} from "src/components/expression/logics/embedded-data-logic";
import {DurationLogic} from "src/components/expression/logics/duration-logic";

export const Expression = props => {
  const {index, expression, questions, handleCondition, handleLocator} = props;

  switch (expression.locator_type) {
    case expressionLogicType.EMBEDDED_DATA:
      return (
        <EmbeddedDataLogic
          index={index}
          expression={expression}
          handleLocator={handleLocator}
        />
      );
    case expressionLogicType.DURATION:
      return (
        <DurationLogic
          index={index}
          expression={expression}
          handleLocator={handleLocator}
        />
      );
    default:
      return (
        <QuestionLogic
          index={index}
          questions={questions}
          expression={expression}
          handleCondition={handleCondition}
          handleLocator={handleLocator}
        />
      );
  }
};
