export const enSurveyTranslations = {
  survey: "Survey",
  next: "Next",
  prev: "Previous",
  pageErrors: "There are errors. Check you answers.",
  requiredQuestionError: "Required Question!",
  integerInputTypeError: "Integer Input Is Required!",
  copySurveyURL: "Copy Survey URL",
  copySurveyURLSuccessful: "Survey URL Copied",
  other: "Other",
  otherOptionEmptyError: "Other option cannot be empty.",
  defaultEndPageMessage: "Thank you for your participation!",
  surveyUrlCopied: "Survey URL was successfully copied!",
  surveyUrlNotCopied: "Survey URL was not copied!",
  copySurveyLink: "Copy Survey Link",
};
