import axiosInstance from "src/services/axiosInstance";

class BlockService {
  async createBlock(payload) {
    return await axiosInstance
      .post(`/blocks`, payload)
      .then(block => {
        return block?.data;
      })
      .catch(err => {
        console.log("err");
        console.log(err);
      });
  }

  async updateBlock(blockId, payload) {
    return await axiosInstance
      .put(`/blocks/${blockId}`, payload)
      .then(block => {
        return block?.data;
      })
      .catch(err => {
        console.log("err");
      });
  }

  async removeBlock(blockId) {
    return await axiosInstance
      .delete(`/blocks/${blockId}`)
      .then(survey => {
        return survey?.data;
      })
      .catch(err => {
        console.log("err");
      });
  }

  async createBlockPages(block_id) {
    return await axiosInstance
      .post(`/blocks/${block_id}/pages`)
      .then(block => {
        console.log(block);
        return block?.data;
      })
      .catch(err => {
        console.log("err");
      });
  }

  async updateBlockQuestions(block_id, payload) {
    return await axiosInstance
      .post(`/blocks/${block_id}/questions`, payload)
      .then(block => {
        return block?.data;
      })
      .catch(err => {
        console.log("err");
      });
  }
}

export const blockService = new BlockService();
