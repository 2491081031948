import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {
  Box,
  Stack,
  Typography,
  LinearProgress,
  Grid,
  Button,
  Paper,
  Card,
  CardContent,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  SvgIcon,
  IconButton,
  Tooltip,
  MenuItem,
  InputLabel,
  Select,
  useTheme,
  createTheme,
  ThemeProvider,
  Slide,
  Fade,
  Grow,
  Zoom,
  Collapse,
} from "@mui/material";

import LogoSettings from "src/components/design/logo-settings";
import GeneralSettings from "src/components/design/general-settings";
import StyleSettings from "src/components/design/style-settings";
import BackgroundSettings from "src/components/design/background-settings";
import MotionSettings from "src/components/design/motion-settings";

const questions = [
  {
    category: "Genel Tercihler",
    questions: [
      {
        question: "Günlük olarak hangi cihazı daha sık kullanıyorsunuz?",
        options: [
          "Akıllı Telefon",
          "Tablet",
          "Dizüstü Bilgisayar",
          "Masaüstü Bilgisayar",
        ],
      },
      {
        question: "Tercih ettiğiniz tatil türü nedir?",
        options: ["Plaj Tatili", "Doğa Tatili", "Şehir Tatili", "Kültür Gezisi"],
      },
      {
        question: "Hangi sosyal medya platformunu daha sık kullanıyorsunuz?",
        options: ["Facebook", "Instagram", "Twitter", "LinkedIn"],
      },
    ],
  },
  {
    category: "Teknoloji Kullanımı",
    questions: [
      {
        question: "Hangi işletim sistemini kullanıyorsunuz?",
        options: ["Windows", "macOS", "Linux", "Diğer"],
      },
      {
        question: "Günlük ortalama ekran süreniz nedir?",
        options: ["1-2 saat", "3-4 saat", "5-6 saat", "7 saat ve üzeri"],
      },
      {
        question: "Hangi uygulamayı en çok kullanıyorsunuz?",
        options: ["WhatsApp", "YouTube", "Instagram", "TikTok"],
      },
    ],
  },
  {
    category: "Yeme İçme Alışkanlıkları",
    questions: [
      {
        question: "Kahvaltıda ne yemeyi tercih edersiniz?",
        options: ["Yumurta", "Peynir", "Tahıl Gevreği", "Meyve"],
      },
      {
        question: "Günde kaç fincan kahve içiyorsunuz?",
        options: ["Hiç", "1-2 fincan", "3-4 fincan", "5 fincan ve üzeri"],
      },
      {
        question: "Hangi mutfağı tercih edersiniz?",
        options: ["Türk Mutfağı", "İtalyan Mutfağı", "Çin Mutfağı", "Meksika Mutfağı"],
      },
    ],
  },
];

const TabContent = ({value, index, children}) => (
  <Box role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`}>
    {value === index && <Box sx={{p: 3}}>{children}</Box>}
  </Box>
);

const DesktopIcon = () => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.75 3.375C0.75 2.33946 1.58947 1.5 2.625 1.5H21.375C22.4105 1.5 23.25 2.33947 23.25 3.375V15.3933C23.25 16.4288 22.4105 17.2683 21.375 17.2683H2.625C1.58947 17.2683 0.75 16.4288 0.75 15.3933V3.375ZM2.625 3C2.41789 3 2.25 3.16789 2.25 3.375V15.3933C2.25 15.6004 2.41789 15.7683 2.625 15.7683H21.375C21.5821 15.7683 21.75 15.6004 21.75 15.3933V3.375C21.75 3.16789 21.5821 3 21.375 3H2.625Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.45959 16.3972C8.5189 16.0345 8.83226 15.7683 9.19976 15.7683H14.7998C15.1673 15.7683 15.4806 16.0345 15.5399 16.3972L16.2399 20.6777L15.4998 20.7987V21.5487H8.49976C8.27969 21.5487 8.07073 21.4521 7.92823 21.2844C7.78573 21.1167 7.72407 20.8949 7.75959 20.6777L8.45959 16.3972ZM14.6171 20.0487L14.1624 17.2683H9.83707L9.38237 20.0487H14.6171Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.6499 20.7988C5.6499 20.3846 5.98569 20.0488 6.3999 20.0488H17.5999C18.0141 20.0488 18.3499 20.3846 18.3499 20.7988C18.3499 21.213 18.0141 21.5488 17.5999 21.5488H6.3999C5.98569 21.5488 5.6499 21.213 5.6499 20.7988Z"
    />
  </SvgIcon>
);

const MobileIcon = () => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24">
    <path d="M12.0001 17.7065C11.4969 17.7065 11.0482 18.1078 11.0482 18.6522C11.0482 19.1966 11.4969 19.5978 12.0001 19.5978C12.5034 19.5978 12.9521 19.1966 12.9521 18.6522C12.9521 18.1078 12.5034 17.7065 12.0001 17.7065Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.25 2.25C7.00736 2.25 6 3.25736 6 4.5V19.5C6 20.7426 7.00736 21.75 8.25 21.75H15.75C16.9926 21.75 18 20.7426 18 19.5V4.5C18 3.25736 16.9926 2.25 15.75 2.25H8.25ZM16.5 15.5543V4.5C16.5 4.08579 16.1642 3.75 15.75 3.75H8.25C7.83579 3.75 7.5 4.08579 7.5 4.5V15.5543H16.5ZM7.5 17.0543H16.5V19.5C16.5 19.9142 16.1642 20.25 15.75 20.25H8.25C7.83579 20.25 7.5 19.9142 7.5 19.5V17.0543Z"
    />
  </SvgIcon>
);

export const animationTypes = {
  fade: Fade,
  grow: Grow,
  zoom: Zoom,
  collapse: Collapse,
};

export const DesignTab = () => {
  const {t} = useTranslation();
  const theme = useTheme();

  const [logoSettings, setLogoSettings] = useState({
    logo: null,
    placement: "Center",
    verticalPlacement: "Top",
    maxHeight: 70,
    maxWidth: 100,
  });
  const [generalSettings, setGeneralSettings] = useState({
    progressBar: "WithText",
    progressBarPlacement: "Center",
    progressBarVerticalPlacement: "Top",
    nextButton: " → ",
    previousButton: " ← ",
  });
  const [styleSettings, setStyleSettings] = useState({
    fontFamily: theme.typography.fontFamily,
    questionFontSize: 18,
    answerFontSize: 16,
    primaryColor: theme.palette.primary.main,
    secondaryColor: theme.palette.secondary.main,
  });
  const [backgroundSettings, setBackgroundSettings] = useState({
    backgroundColor: theme.palette.background.default,
  });
  const [motionSettings, setMotionSettings] = useState({
    pageTransition: "slide",
    translationDirection: "right",
  });
  const newTheme = createTheme({
    palette: {
      primary: {
        main: styleSettings?.primaryColor, // Primary color
      },
      secondary: {
        main: styleSettings?.secondaryColor, // Secondary color
      },
      background: {
        default: backgroundSettings?.backgroundColor, // Custom default background color
      },
    },
    typography: {
      fontFamily: styleSettings?.fontFamily,
      fontSize: styleSettings?.answerFontSize,
      h6: {
        fontSize: styleSettings?.questionFontSize,
      },
      // Define other typography variants if needed
    },
  });

  const [tabValue, setTabValue] = useState(0);

  const handleClick = index => {
    setTabValue(index);
  };

  const surveys = [
    t("designTab.previewSide.mySurvey"),
    t("designTab.previewSide.sampleSurvey"),
  ]; // This could come from props or state

  const [selectedSurvey, setSelectedSurvey] = useState(
    t("designTab.previewSide.sampleSurvey"),
  );

  const [selectedPreview, setSelectedPreview] = useState("Desktop");

  const handleMenuItemClick = previewType => {
    setSelectedPreview(previewType);
  };

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedValue, setSelectedValue] = useState("");
  const [showQuestion, setShowQuestion] = useState(true);

  const handleChange = event => {
    setSelectedValue(event.target.value);
  };

  const handleButton = number => {
    setShowQuestion(false);
    setTimeout(() => {
      setCurrentQuestion(prev => prev + number);
      setShowQuestion(true);
    }, 500); // 500ms animasyon süresi
  };

  const Animation =
    motionSettings?.pageTransition !== "slide" ? (
      animationTypes[motionSettings?.pageTransition]
    ) : (
      <></>
    );

  return (
    <Box sx={{display: "flex", height: "100%"}}>
      {/* Side Navigation */}
      <Paper
        sx={{
          width: 240,
          height: "100%",
          display: "flex",
          flexDirection: "column",
          p: 2,
        }}
      >
        <Button
          sx={{
            mb: 1,
          }}
          variant={tabValue === 0 ? "contained" : "text"}
          onClick={() => handleClick(0)}
        >
          {t("designTab.logo")}
        </Button>
        <Button
          sx={{
            mb: 1,
          }}
          variant={tabValue === 1 ? "contained" : "text"}
          onClick={() => handleClick(1)}
        >
          {t("designTab.general")}
        </Button>

        <Button
          sx={{
            mb: 1,
          }}
          variant={tabValue === 2 ? "contained" : "text"}
          onClick={() => handleClick(2)}
        >
          {t("designTab.style")}
        </Button>
        <Button
          sx={{
            mb: 1,
          }}
          variant={tabValue === 3 ? "contained" : "text"}
          onClick={() => handleClick(3)}
        >
          {t("designTab.background")}
        </Button>
        <Button
          sx={{
            mb: 1,
          }}
          variant={tabValue === 4 ? "contained" : "text"}
          onClick={() => handleClick(4)}
        >
          {t("designTab.motion")}
        </Button>
      </Paper>

      {/* Main Content */}
      <Box sx={{flexGrow: 1, display: "flex"}}>
        {/* Left Half: Tab Content */}
        <Box
          sx={{
            width: 400,
            minwidth: 400,
            maxwidth: 400,
            // flex: 1,
            display: "flex",
            flexDirection: "column",
            borderRight: "1px solid #ccc", // Optional border to separate
          }}
        >
          <TabContent value={tabValue} index={0}>
            <Card sx={{my: 2}}>
              <CardContent>
                <Grid container>
                  <Grid xs={12} item mb={2}>
                    <Typography variant="h5">{t("designTab.logo")}</Typography>
                  </Grid>
                  <Grid xs={12} item>
                    <LogoSettings
                      logoSettings={logoSettings}
                      setLogoSettings={setLogoSettings}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </TabContent>
          <TabContent value={tabValue} index={1}>
            <Card sx={{my: 2}}>
              <CardContent>
                <Grid container>
                  <Grid xs={12} item mb={2}>
                    <Typography variant="h5">{t("designTab.general")}</Typography>
                  </Grid>
                  <Grid xs={12} item>
                    <GeneralSettings
                      generalSettings={generalSettings}
                      setGeneralSettings={setGeneralSettings}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </TabContent>
          <TabContent value={tabValue} index={2}>
            <Card sx={{my: 2}}>
              <CardContent>
                <Grid container>
                  <Grid xs={12} item mb={2}>
                    <Typography variant="h5">{t("designTab.style")}</Typography>
                  </Grid>
                  <Grid xs={12} item>
                    <StyleSettings
                      styleSettings={styleSettings}
                      setStyleSettings={setStyleSettings}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </TabContent>
          <TabContent value={tabValue} index={3}>
            <Card sx={{my: 2}}>
              <CardContent>
                <Grid container>
                  <Grid xs={12} item mb={2}>
                    <Typography variant="h5">{t("designTab.background")}</Typography>
                  </Grid>
                  <Grid xs={12} item>
                    <BackgroundSettings
                      backgroundSettings={backgroundSettings}
                      setBackgroundSettings={setBackgroundSettings}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </TabContent>
          <TabContent value={tabValue} index={4}>
            <Card sx={{my: 2}}>
              <CardContent>
                <Grid container>
                  <Grid xs={12} item mb={2}>
                    <Typography variant="h5">{t("designTab.motion")}</Typography>
                  </Grid>
                  <Grid xs={12} item>
                    <MotionSettings
                      motionSettings={motionSettings}
                      setMotionSettings={setMotionSettings}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </TabContent>
        </Box>

        {/* Right Half: Static Text */}
        <ThemeProvider theme={newTheme}>
          <Box
            sx={{
              flex: 1,
              padding: 20,
              overflowYy: "auto" /* To handle overflow content */,
              p: 2,
              backgroundColor: backgroundSettings?.backgroundColor, // Optional background color
            }}
          >
            <Box
              sx={{
                p: 2,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box sx={{display: "flex", alignItems: "center"}}>
                <Tooltip title={t("designTab.previewSide.viewDesktop")} sx={{mr: 20}}>
                  <IconButton
                    aria-label="View Desktop"
                    aria-selected={selectedPreview === "Desktop"}
                    onClick={() => handleMenuItemClick("Desktop")}
                    sx={{
                      backgroundColor:
                        selectedPreview === "Desktop"
                          ? theme => `${theme.palette.primary.main}33` // 20% opacity using hex color code with alpha
                          : "transparent", // Transparent background when not selected
                      "&:hover": {
                        backgroundColor:
                          selectedPreview === "Desktop"
                            ? theme => `${theme.palette.primary.main}33` // 20% opacity on hover if selected
                            : "rgba(0, 0, 0, 0.08)", // Light grey for hover if not selected
                      },
                      color:
                        selectedPreview === "Desktop"
                          ? theme => theme.palette.primary.main
                          : "inherit", // Icon color
                      borderRadius: "8px", // Set border radius
                      marginRight: "4px",
                    }}
                  >
                    <DesktopIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title={t("designTab.previewSide.viewMobile")}>
                  <IconButton
                    aria-label="View Mobile"
                    aria-selected={selectedPreview === "Mobile"}
                    onClick={() => handleMenuItemClick("Mobile")}
                    sx={{
                      backgroundColor:
                        selectedPreview === "Mobile"
                          ? theme => `${theme.palette.primary.main}33` // 20% opacity using hex color code with alpha
                          : "transparent", // Transparent background when not selected
                      "&:hover": {
                        backgroundColor:
                          selectedPreview === "Mobile"
                            ? theme => `${theme.palette.primary.main}33` // 20% opacity on hover if selected
                            : "rgba(0, 0, 0, 0.08)", // Light grey for hover if not selected
                      },
                      color:
                        selectedPreview === "Mobile"
                          ? theme => theme.palette.primary.main
                          : "inherit", // Icon color
                      borderRadius: "8px", // Set border radius
                    }}
                  >
                    <MobileIcon />
                  </IconButton>
                </Tooltip>
              </Box>
              <Box sx={{flexGrow: 1}} />
              <Box sx={{right: 16}}>
                <FormControl fullWidth>
                  <InputLabel id="survey-select-label">
                    {t("designTab.previewSide.selectSurvey")}
                  </InputLabel>
                  <Select
                    labelId="survey-select-label"
                    id="survey-select"
                    value={selectedSurvey}
                    onChange={e => setSelectedSurvey(e.target.value)}
                    label="Select Survey"
                  >
                    {surveys.map(survey => (
                      <MenuItem key={survey} value={survey}>
                        {survey}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Box>

            <Box
              sx={{
                width: selectedPreview === "Desktop" ? 720 : "450px", // Width of the iPhone frame in mobile view
                maxWidth: selectedPreview === "Desktop" ? "50%" : "100%", // Width of the iPhone frame in mobile view
                height: selectedPreview === "Desktop" ? "auto" : "76vh", // Height of the iPhone frame in mobile view
                maxHeight: selectedPreview === "Desktop" ? "auto" : "76vh", // Height of the iPhone frame in mobile view
                border: selectedPreview === "Mobile" ? "16px solid #ccc" : "none", // Outer iPhone frame border
                borderRadius: selectedPreview === "Mobile" ? "36px" : "none", // Outer iPhone frame border-radius
                paddingTop: selectedPreview === "Mobile" ? "5vh" : 0, // Outer iPhone frame border-radius
                position: "relative",
                overflow: "hidden",
                mx: "auto",
                mt: 2,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {selectedPreview === "Mobile" && (
                <Box
                  sx={{
                    position: "absolute",
                    top: "12px", // Position the notch slightly from the top
                    left: "50%",
                    transform: "translateX(-50%)",
                    width: "100px", // Width of the island notch
                    height: "20px", // Height of the island notch
                    borderRadius: "40px", // Rounded corners for island notch
                    backgroundColor: "#ddd", // Notch color
                    boxShadow: "0 0 0 2px rgba(0, 0, 0, 0.1)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    border: "1px solid #ddd", // Optional border for the notch
                  }}
                />
              )}

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: "70vh",
                  width: "100%",
                }}
              >
                {/* Üst Kısım - Sabit Logo */}
                {(logoSettings?.verticalPlacement === "Top" ||
                  (generalSettings?.progressBar !== "None" &&
                    generalSettings?.progressBarVerticalPlacement === "Top")) && (
                  <Box
                    sx={{
                      flex: "0 0 100px", // Sabit yükseklik
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "#f5f5f5",
                      flexDirection: "column",
                      padding: 1,
                    }}
                  >
                    {logoSettings?.verticalPlacement === "Top" && (
                      <Stack
                        width="100%"
                        height="100%"
                        flexDirection="column"
                        alignItems={
                          logoSettings?.placement === "Left"
                            ? "flex-start" // Sol hizalama
                            : logoSettings?.placement === "Right"
                              ? "flex-end" // Sağ hizalama
                              : "center" // Orta hizalama
                        }
                        justifyContent="center" // Dikeyde ortalama
                      >
                        <Box width={200} display="flex" justifyContent="center" m={1}>
                          {logoSettings?.logo ? (
                            <img
                              src={logoSettings?.logo}
                              alt="Selected"
                              width={100}
                              style={{
                                maxHeight: Number(logoSettings?.maxHeight) || "auto",
                                maxWidth: Number(logoSettings?.maxWidth) || "auto",
                                objectFit: "contain", // Ensure image fits within container
                              }}
                            />
                          ) : (
                            <img
                              className="logo"
                              src="/logo.png"
                              alt="Galata Anket logo"
                              width="71"
                              height="50"
                            />
                          )}
                        </Box>
                      </Stack>
                    )}

                    {generalSettings?.progressBar !== "None" &&
                      generalSettings?.progressBarVerticalPlacement === "Top" && (
                        <Stack
                          width="100%" // Konteyner genişliğini tam genişlik yapın
                          alignItems={
                            generalSettings?.progressBarPlacement === "Left"
                              ? "flex-start" // Sola hizala
                              : generalSettings?.progressBarPlacement === "Right"
                                ? "flex-end" // Sağa hizala
                                : "center" // Ortala
                          }
                          justifyContent="center" // Dikey ortala
                          direction="column" // Dikey yerleşim
                        >
                          <Box width={200} textAlign={"center"} m={1}>
                            <Box width="100%">
                              <LinearProgress variant="determinate" value={0} />
                            </Box>
                            {generalSettings?.progressBar === "WithText" && (
                              <Typography variant="body2" align="center">
                                0%
                              </Typography>
                            )}
                          </Box>
                        </Stack>
                      )}
                  </Box>
                )}

                <Box
                  sx={{
                    flex: "1 1 auto",
                    overflowY: "auto",
                    backgroundColor: "#fafafa",
                    paddingTop: 2,
                  }}
                >
                  {motionSettings?.pageTransition === "slide" ? (
                    <Slide
                      direction={motionSettings?.translationDirection}
                      in={showQuestion}
                      mountOnEnter
                      unmountOnExit
                    >
                      {/* Orta Kısım - Kaydırılabilir Sorular */}
                      <Stack sx={{paddingLeft: 2, paddingRight: 2}}>
                        {questions[currentQuestion].questions.map((question, index) => (
                          <Paper
                            key={index}
                            elevation={3}
                            sx={{
                              mb: 2,
                              p: 4,
                              backgroundColor: "rgba(255, 255, 255, 0.9)", // Hafif şeffaf bir arka plan
                              borderRadius: 3, // Yuvarlatılmış köşeler
                              boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)", // Daha belirgin bir gölge
                            }}
                          >
                            <Typography
                              variant="h6"
                              gutterBottom
                              sx={{fontWeight: "bold", color: "#333"}}
                            >
                              {index + 1}. {question.question}
                            </Typography>
                            <FormControl component="fieldset">
                              <RadioGroup value={selectedValue} onChange={handleChange}>
                                {question.options.map((option, index) => (
                                  <FormControlLabel
                                    key={index}
                                    value={option}
                                    control={<Radio />}
                                    label={option}
                                    sx={{
                                      "& .MuiFormControlLabel-label": {
                                        // Şık metin stili
                                        color: "#555",
                                        fontSize: "1rem",
                                      },
                                    }}
                                  />
                                ))}
                              </RadioGroup>
                            </FormControl>
                          </Paper>
                        ))}
                      </Stack>
                    </Slide>
                  ) : (
                    <Animation in={showQuestion}>
                      {/* Orta Kısım - Kaydırılabilir Sorular */}

                      {/* Orta Kısım - Kaydırılabilir Sorular */}
                      <Stack sx={{paddingLeft: 2, paddingRight: 2}}>
                        {questions[currentQuestion].questions.map((question, index) => (
                          <Paper
                            key={index}
                            elevation={3}
                            sx={{
                              mb: 2,
                              p: 4,
                              backgroundColor: "rgba(255, 255, 255, 0.9)", // Hafif şeffaf bir arka plan
                              borderRadius: 3, // Yuvarlatılmış köşeler
                              boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)", // Daha belirgin bir gölge
                            }}
                          >
                            <Typography
                              variant="h6"
                              gutterBottom
                              sx={{fontWeight: "bold", color: "#333"}}
                            >
                              {index + 1}. {question.question}
                            </Typography>
                            <FormControl component="fieldset">
                              <RadioGroup value={selectedValue} onChange={handleChange}>
                                {question.options.map((option, index) => (
                                  <FormControlLabel
                                    key={index}
                                    value={option}
                                    control={<Radio />}
                                    label={option}
                                    sx={{
                                      "& .MuiFormControlLabel-label": {
                                        // Şık metin stili
                                        color: "#555",
                                        fontSize: "1rem",
                                      },
                                    }}
                                  />
                                ))}
                              </RadioGroup>
                            </FormControl>
                          </Paper>
                        ))}
                      </Stack>
                    </Animation>
                  )}
                </Box>

                {/* Alt Kısım - Sabit İleri ve Geri Butonları */}

                <Box
                  sx={{
                    flex: "0 0 80px", // Sabit yükseklik
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "0 20px",
                    backgroundColor: "#f5f5f5",
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    type="button"
                    onClick={() => handleButton(-1)}
                    disabled={currentQuestion === 0}
                  >
                    {generalSettings?.previousButton
                      ? generalSettings?.previousButton
                      : "Previous"}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    type="button"
                    onClick={() => handleButton(+1)}
                    disabled={currentQuestion >= questions.length - 1}
                  >
                    {generalSettings?.nextButton ? generalSettings?.nextButton : "Next"}
                  </Button>
                </Box>

                {(logoSettings?.verticalPlacement === "Bottom" ||
                  (generalSettings?.progressBar !== "None" &&
                    generalSettings?.progressBarVerticalPlacement === "Bottom")) && (
                  <Box
                    sx={{
                      flex: "0 0 80px", // Sabit yükseklik
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      backgroundColor: "#f5f5f5",
                      flexDirection: "column",
                      padding: 1,
                    }}
                  >
                    {logoSettings?.verticalPlacement === "Bottom" && (
                      <Stack
                        width="100%"
                        height="100%"
                        flexDirection="column"
                        alignItems={
                          logoSettings?.placement === "Left"
                            ? "flex-start" // Sol hizalama
                            : logoSettings?.placement === "Right"
                              ? "flex-end" // Sağ hizalama
                              : "center" // Orta hizalama
                        }
                        justifyContent="center" // Dikeyde ortalama
                      >
                        <Box width={200} display="flex" justifyContent="center">
                          {logoSettings?.logo ? (
                            <img
                              src={logoSettings?.logo}
                              alt="Selected"
                              width={100}
                              style={{
                                maxHeight: logoSettings?.maxHeight,
                              }}
                            />
                          ) : (
                            <img
                              className="logo"
                              src="/logo.png"
                              alt="Galata Anket logo"
                              width="71"
                              height="50"
                            />
                          )}
                        </Box>
                      </Stack>
                    )}
                    {generalSettings?.progressBar !== "None" &&
                      generalSettings?.progressBarVerticalPlacement === "Bottom" && (
                        <Stack
                          width="100%" // Konteyner genişliğini tam genişlik yapın
                          alignItems={
                            generalSettings?.progressBarPlacement === "Left"
                              ? "flex-start" // Sola hizala
                              : generalSettings?.progressBarPlacement === "Right"
                                ? "flex-end" // Sağa hizala
                                : "center" // Ortala
                          }
                          justifyContent="center" // Dikey ortala
                          direction="column" // Dikey yerleşim
                        >
                          <Box width={200} textAlign={"center"} m={2}>
                            <Box width="100%">
                              <LinearProgress variant="determinate" value={0} />
                            </Box>
                            {generalSettings?.progressBar === "WithText" && (
                              <Typography variant="body2" align="center">
                                0%
                              </Typography>
                            )}
                          </Box>
                        </Stack>
                      )}
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        </ThemeProvider>
      </Box>
    </Box>
  );
};
