import React from "react";
import {Box} from "@mui/material";
import ColorPicker from "./color-picker";
import {useTranslation} from "react-i18next";

const BackgroundSettings = ({backgroundSettings, setBackgroundSettings}) => {
  const {t} = useTranslation();

  return (
    <Box>
      <Box className="lfe-settings-component-container" mb={2}>
        <ColorPicker
          label={t("designTab.backgroundStyles.backgroundColor")}
          color={backgroundSettings?.backgroundColor}
          setColor={color =>
            setBackgroundSettings({
              ...backgroundSettings,
              backgroundColor: color,
            })
          }
        />
      </Box>
    </Box>
  );
};

export default BackgroundSettings;
