import React from "react";
import {Box, MenuItem, Select, InputLabel, FormControl, useTheme} from "@mui/material";
import {ArrowDropDownIcon} from "@mui/x-date-pickers";
import QuantityInput from "./number-input";
import ColorPicker from "./color-picker";
import {useTranslation} from "react-i18next";

const StyleSettings = ({styleSettings, setStyleSettings}) => {
  const theme = useTheme();
  const {t} = useTranslation();

  const fontOptions = [
    {label: "Default", value: theme.typography.fontFamily},
    {label: "Arial", value: "Arial, sans-serif"},
    {label: "Times New Roman", value: "'Times New Roman', serif"},
    {label: "Courier New", value: "'Courier New', monospace"},
    {label: "Georgia", value: "Georgia, serif"},
    {label: "Verdana", value: "Verdana, sans-serif"},
    {label: "Trebuchet MS", value: "'Trebuchet MS', sans-serif"},
    {label: "Comic Sans MS", value: "'Comic Sans MS', cursive, sans-serif"},
    {label: "Impact", value: "Impact, sans-serif"},
    {label: "Tahoma", value: "Tahoma, sans-serif"},
    {label: "Palatino", value: "'Palatino Linotype', 'Book Antiqua', Palatino, serif"},
    {
      label: "Lucida Sans Unicode",
      value: "'Lucida Sans Unicode', 'Lucida Grande', sans-serif",
    },
  ];

  return (
    <Box>
      <Box className="lfe-settings-component-container" mb={2}>
        <ColorPicker
          label={t("designTab.styleStyles.primaryColor")}
          color={styleSettings?.primaryColor}
          setColor={color =>
            setStyleSettings({
              ...styleSettings,
              primaryColor: color,
            })
          }
        />
      </Box>
      <Box className="lfe-settings-component-container" mb={2}>
        <ColorPicker
          label={t("designTab.styleStyles.secondaryColor")}
          color={styleSettings?.secondaryColor}
          setColor={color =>
            setStyleSettings({
              ...styleSettings,
              secondaryColor: color,
            })
          }
        />
      </Box>
      <Box className="lfe-settings-component-container" mb={2}>
        <FormControl fullWidth variant="outlined">
          <InputLabel id="lfe-font-selector-label">
            {t("designTab.styleStyles.font")}
          </InputLabel>
          <Select
            labelId="lfe-font-selector-label"
            id="lfe-font-selector"
            value={styleSettings?.fontFamily}
            onChange={event =>
              setStyleSettings({
                ...styleSettings,
                fontFamily: event.target.value,
              })
            }
            label={t("designTab.styleStyles.font")}
            IconComponent={ArrowDropDownIcon}
          >
            {fontOptions.map(option => (
              <MenuItem
                key={option.value}
                value={option.value}
                style={{fontFamily: option.value}}
              >
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box className="lfe-settings-component-container" mb={2}>
        <QuantityInput
          value={styleSettings?.questionFontSize}
          onChange={value =>
            setStyleSettings({
              ...styleSettings,
              questionFontSize: Number(value),
            })
          }
          min={1}
          max={99}
          label={t("designTab.styleStyles.questionFontSize")}
        />
      </Box>
      <Box className="lfe-settings-component-container" mb={2}>
        <QuantityInput
          value={styleSettings?.answerFontSize}
          onChange={value =>
            setStyleSettings({
              ...styleSettings,
              answerFontSize: Number(value),
            })
          }
          min={1}
          max={99}
          label={t("designTab.styleStyles.answersFontSize")}
        />
      </Box>
    </Box>
  );
};

export default StyleSettings;
