import Box from "@mui/material/Box";
import {CardContent, Tooltip, Typography} from "@mui/material";
import Card from "@mui/material/Card";
import React from "react";
import {useSurveyEngine} from "src/hooks/use-survey-engine";
import SvgIcon from "@mui/material/SvgIcon";
import IconButton from "@mui/material/IconButton";
import Link03Icon from "@untitled-ui/icons-react/build/esm/Link03";
import {paths} from "src/pages/paths";
import {useParams, useSearchParams} from "react-router-dom";
import {useAuth} from "src/hooks/use-auth";
import {useTranslation} from "react-i18next";

export default function EndPage() {
  const {t} = useTranslation();
  const surveyEngine = useSurveyEngine();
  const [surveyParams, setSurveyParams] = useSearchParams();
  const {uuid} = useParams();
  const auth = useAuth();

  const handleCopySurveyUrl = () => {
    const parameterArray = ["share=true"];
    for (let entry of surveyParams.entries()) {
      if (entry[0] !== "share") {
        parameterArray.push(`${entry[0]}=${entry[1]}`);
      }
    }
    const parameters = parameterArray.join("&");
    navigator.clipboard
      .writeText(`${window.location.origin}${paths.survey}/${uuid}?${parameters}`)
      .then(_ => auth.showAlert("success", t("surveyUrlCopied"))) // FIXME: use locales
      .catch(_ => auth.showAlert("error", t("surveyUrlNotCopied")));
  };

  return (
    <Box sx={{px: "5%"}}>
      <Card>
        <CardContent>
          {surveyEngine.survey.end_page_message ? (
            <Typography
              dangerouslySetInnerHTML={{
                __html: surveyEngine.survey.end_page_message,
              }}
            />
          ) : (
            <Typography textAlign={"center"} p={2}>
              {t("defaultEndPageMessage")}
            </Typography>
          )}

          {surveyEngine?.survey?.share_link && (
            <Box display="center" justifyContent="center">
              <Tooltip title={t("copySurveyLink")} placement="left" arrow>
                <IconButton onClick={handleCopySurveyUrl} color={"primary"}>
                  <SvgIcon>
                    <Link03Icon />
                  </SvgIcon>
                </IconButton>
              </Tooltip>
            </Box>
          )}
        </CardContent>
      </Card>
    </Box>
  );
}
