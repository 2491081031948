import {Box, IconButton, Link, Grid, Typography} from "@mui/material";
import {Container, Stack} from "@mui/system";
import FacebookIcon from "@mui/icons-material/Facebook";
import XIcon from "@mui/icons-material/X";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import {useTranslation} from "react-i18next";
import {useCallback} from "react";
import {paths} from "src/pages/paths";
import {useRouter} from "src/hooks/use-router";

export const Footer = () => {
  const {t} = useTranslation();

  // const useNavigateToHowToUseGiftCodes = () => {
  //   const router = useRouter();

  //   return useCallback(() => {
  //     window.location.href = paths.giftcodesinstructions;
  //   }, []);
  // };

  // const navigateToHowToUseGiftCodes = useNavigateToHowToUseGiftCodes();

  return (
    <Box sx={{backgroundColor: "#f8f8f8", py: 6}}>
      <Container maxWidth="xl">
        <Grid container spacing={4}>
          {/* Logo and Address */}
          <Grid item xs={12} md={3}>
            <Typography variant="h3" color="textPrimary">
              Galata Anket
            </Typography>

            <Box
              sx={{
                mt: 3,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Stack direction="row" alignItems="center" spacing={1} sx={{mb: 1}}>
                <EmailIcon color="action" />
                <Typography variant="body2" color="textSecondary">
                  anket@galataanket.com
                </Typography>
              </Stack>
            </Box>
          </Grid>

          <Grid item xs={12} md={3}>
            <Typography variant="h6" color="textPrimary">
              {t("pages")}
            </Typography>

            <Box sx={{display: "flex", flexDirection: "column", mt: 2}}>
              <Link
                href="/documents/Galata Anket - İnternet Sitesi Aydınlatma Metni.pdf"
                target="_blank"
                rel="noopener noreferrer"
                color="inherit"
                sx={{mb: 1}}
                variant="body2"
              >
                {t("websiteDisclosure")}
              </Link>
              <Link
                href="/documents/Galata Anket - İnternet Sitesi Gizlilik ve Çerez Politikası.pdf"
                target="_blank"
                rel="noopener noreferrer"
                color="inherit"
                sx={{mb: 1}}
                variant="body2"
              >
                {t("cookiePrivacyPolicy")}
              </Link>
              <Link
                href="/documents/Galata Anket - İnternet Sitesi İlgili Kişi Başvuru Formu.pdf"
                target="_blank"
                rel="noopener noreferrer"
                color="inherit"
                sx={{mb: 1}}
                variant="body2"
              >
                {t("dataSubjectApplicationForm")}
              </Link>
              <Link
                href={paths.giftcodesinstructions}
                color="inherit"
                sx={{mb: 1, textAlign: "left", display: "block"}}
                variant="body2"
              >
                {t("mainPage.FAQ")}
              </Link>
            </Box>
          </Grid>

          <Grid item xs={12} md={3}>
            <Typography variant="h6" color="textPrimary">
              {t("ourAddress")}
            </Typography>

            <Stack direction="row" spacing={1} mt={2}>
              <LocationOnIcon color="action" sx={{fontSize: 20}} />
              <Typography variant="body2" color="textSecondary">
                Yıldız Teknik Üniversitesi Teknopark Çifte Havuzlar Mahallesi Eski Londra
                Asfaltı Caddesi Kuluçka Merkezi A1 BLOK NO:151/1C/B35
                <br />
                ESENLER/İSTANBUL
              </Typography>
            </Stack>
          </Grid>

          <Grid item xs={12} md={3}>
            <Typography variant="h6" color="textPrimary">
              {t("followUs")}
            </Typography>
            <Box sx={{mt: 2}}>
              <IconButton
                href="https://www.facebook.com/galataanket"
                target="_blank"
                color="primary"
              >
                <FacebookIcon />
              </IconButton>
              <IconButton
                href="https://x.com/galataanket"
                target="_blank"
                color="primary"
              >
                <XIcon />
              </IconButton>
              <IconButton
                href="https://www.instagram.com/GalataAnket/"
                target="_blank"
                color="primary"
              >
                <InstagramIcon />
              </IconButton>
              <IconButton
                href="https://www.linkedin.com/company/galata-anket-ara%C5%9Ft%C4%B1rma-a-%C5%9F/"
                target="_blank"
                color="primary"
              >
                <LinkedInIcon />
              </IconButton>
            </Box>
          </Grid>
        </Grid>

        {/* Footer Bottom */}
        <Box sx={{mt: 4, textAlign: "center"}}>
          <Typography variant="body2" color="textSecondary">
            © {new Date().getFullYear()} {t("rightsReserved")}
          </Typography>
        </Box>
      </Container>
      {/* Footer Image */}
      <Box
        component="img"
        src="/footer.svg"
        sx={{
          bottom: 0,
          left: 0,
          width: "100%",
          maxHeight: "200px",
          mt: 4,
        }}
      />
    </Box>
  );
};
