import {createContext, useEffect, useState} from "react";
import GeneralAlert from "src/components/general-alert";
import {newauthService} from "src/new_services/auth";
import {useNavigate} from "react-router-dom";
import {paths} from "src/pages/paths";
import {ACCESS_TOKEN_KEY} from "src/services/axiosInstance";
import {useTranslation} from "react-i18next";
import {User} from "src/entities/user";

export const AuthContext = createContext({
  user: null,
});

export const AuthProvider = props => {
  const {children} = props;
  const navigate = useNavigate();
  const [user, setUser] = useState(User.createDefaultUser());
  const {t} = useTranslation();

  const [alert, setAlert] = useState({
    open: false,
    type: "success",
    message: "",
  });

  useEffect(() => {
    const token = localStorage.getItem(ACCESS_TOKEN_KEY);

    if (token) {
      newauthService.me().then(response => {
        if (response) {
          setUser(new User(response));
        } else {
          user.is_initialized = true;
          setUser(user.clone());
          signOut();
        }
      });
    }
  }, []);

  const isLoggedIn = _ => {
    if (user.is_authenticated) {
      return true;
    }
    return false;
  };

  const signIn = async (email, password) => {
    return await newauthService.signIn(email, password).then(response => {
      setUser(new User(response));
    });
  };

  const register = async emailOrPhone => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^05\d{9}$/;

    const data = {};
    if (emailRegex.test(emailOrPhone)) {
      data["email"] = emailOrPhone;
    } else if (phoneRegex.test(emailOrPhone)) {
      data["phone"] = emailOrPhone;
    }

    return await newauthService.register(data);
  };

  const resetPassword = async emailOrPhone => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^05\d{9}$/;

    const data = {};
    if (emailRegex.test(emailOrPhone)) {
      data["email"] = emailOrPhone;
    } else if (phoneRegex.test(emailOrPhone)) {
      data["phone"] = emailOrPhone;
    }
    return await newauthService.resetPassword(data);
  };

  const signOut = async () => {
    return await newauthService.signOut().then(_ => {
      setUser(User.createDefaultUser());
      navigate(paths.auth.login);
    });
  };

  const showAlert = (type, message) => {
    setAlert({
      open: true,
      type,
      message,
    });
  };

  const closeAlert = () => {
    setAlert(prevState => ({
      ...prevState,
      open: false,
    }));
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        alert,

        isLoggedIn,
        signIn,
        register,
        signOut,
        resetPassword,

        showAlert,
        closeAlert,
      }}
    >
      {children}
      <GeneralAlert
        open={alert.open}
        onClose={closeAlert}
        type={alert.type}
        message={alert.message}
      />
    </AuthContext.Provider>
  );
};

export const AuthConsumer = AuthContext.Consumer;
