import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {TableActionButton} from "src/pages/dashboard/super/participant-requests/participant-request-detail/project-assignment-batches-table/table-action-button";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {superParticipantRequestService} from "src/services/super/project/participant-request";
import {ProjectAssignmentBatch} from "src/entities/project/project-assignment";
import {useParams} from "react-router-dom";
import TablePagination from "@mui/material/TablePagination";

export const ProjectAssignmentBatchesTable = props => {
  const {t} = useTranslation();
  const {id} = useParams();
  const {newBatch, handleNewAssignmentBatch} = props;
  const [projectAssignmentBatches, setProjectAssignmentBatches] = useState([]);
  const [tablePage, setTablePage] = useState(0);
  const [tableTotalCount, setTableTotalCount] = useState(0);

  useEffect(() => {
    if (newBatch) {
      const newProjectAssignmentBatch = new ProjectAssignmentBatch(newBatch);
      const existingProjectAssignmentBatchIndex = projectAssignmentBatches.findIndex(
        item => item.batchId === newProjectAssignmentBatch.batchId,
      );
      if (existingProjectAssignmentBatchIndex >= 0) {
        newProjectAssignmentBatch.incrementAssignedParticipantCount(
          projectAssignmentBatches[existingProjectAssignmentBatchIndex]
            .assignedParticipantCount,
        );
        setProjectAssignmentBatches([
          ...projectAssignmentBatches.slice(0, existingProjectAssignmentBatchIndex),
          newProjectAssignmentBatch,
          ...projectAssignmentBatches.slice(existingProjectAssignmentBatchIndex + 1),
        ]);
      } else {
        if (projectAssignmentBatches.length === 10) {
          setProjectAssignmentBatches([
            newProjectAssignmentBatch,
            ...projectAssignmentBatches.slice(0, -1),
          ]);
        } else {
          setProjectAssignmentBatches([
            newProjectAssignmentBatch,
            ...projectAssignmentBatches,
          ]);
        }
      }
      handleNewAssignmentBatch(null);
    }
  }, [newBatch]);

  const fetchProjectAssignmentBatches = page => {
    superParticipantRequestService
      .listProjectAssignmentBatches(id, page + 1)
      .then(response => {
        const batches = response.results.map(res => new ProjectAssignmentBatch(res));
        setProjectAssignmentBatches(batches);
        setTableTotalCount(response.count);
        setTablePage(page);
      });
  };

  useEffect(() => {
    fetchProjectAssignmentBatches(tablePage);
  }, []);

  return (
    <>
      <Typography variant="h4" gutterBottom sx={{marginTop: 4, marginBottom: 3}}>
        {t("sendParticipant.assignBatches")}
      </Typography>
      <TableContainer component={Paper} sx={{marginBottom: 10}}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t("sendParticipant.topic")}</TableCell>
              <TableCell>{t("sendParticipant.status")}</TableCell>
              <TableCell> {t("sendParticipant.durationMinute")}</TableCell>
              <TableCell>{t("sendParticipant.assignedParticipantCount")}</TableCell>
              <TableCell align="right">{t("tableHeaders.actions")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {projectAssignmentBatches.map(batch => (
              <TableRow key={batch.batchId} hover style={{cursor: "pointer"}}>
                <TableCell>{batch.topic}</TableCell>
                <TableCell>{batch.status}</TableCell>
                <TableCell>{batch.duration}</TableCell>
                <TableCell>{batch.assignedParticipantCount}</TableCell>
                <TableCell align="right">
                  <TableActionButton
                    participantRequestId={id}
                    batchId={batch.batchId}
                    currentStatus={batch.status}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={tableTotalCount}
        onPageChange={(e, newPage) => fetchProjectAssignmentBatches(newPage)}
        onRowsPerPageChange={() => {}}
        page={tablePage}
        rowsPerPage={10}
        // rowsPerPageOptions={[5, 10, 25]}
        rowsPerPageOptions={[10]}
      />
    </>
  );
};
