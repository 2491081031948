import {useTranslation} from "react-i18next";
import TextField from "@mui/material/TextField";
import {Grid} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";

const operands = [
  {
    label: "isLessThanOrEqual",
    value: "is_less_than_or_equal",
  },
  {
    label: "isGreaterThanOrEqual",
    value: "is_greater_than_or_equal",
  },
];

export const DurationLogic = props => {
  const {index, expression, handleLocator} = props;
  const {t} = useTranslation();

  const handleUpdate = e => {
    const targetValue = e.target.value;
    const name = e.target.name;
    handleLocator(index, name, targetValue);
  };

  return (
    <>
      <Grid sx={{pl: 1}} item xs={4}>
        <TextField
          fullWidth
          label={t("operand")}
          name="operand"
          select
          value={expression.locator.operand}
          onChange={handleUpdate}
        >
          {operands.map((operand, index) => (
            <MenuItem key={index} value={operand.value}>
              {t(operand.label)}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid sx={{pl: 1}} item xs={4}>
        <TextField
          fullWidth
          label={t("value")}
          name="right_operand"
          value={expression.locator.right_operand}
          onChange={handleUpdate}
          type={"number"}
        />
      </Grid>
    </>
  );
};
