import {useTranslation} from "react-i18next";
import React, {useEffect} from "react";
import {Grid} from "@mui/material";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";

const operands = [
  {
    label: "isEqual",
    value: "is_equal",
  },
  {
    label: "isNotEqual",
    value: "is_not_equal",
  },
];

export const EmbeddedDataLogic = props => {
  const {index, expression, handleLocator} = props;
  const {t} = useTranslation();

  useEffect(() => {
    if (expression.locator) {
      handleLocator(index, "left_operand", expression.locator.left_operand);
      handleLocator(index, "operand", expression.locator.operand);
      handleLocator(index, "right_operand", expression.locator.right_operand);
    } else {
      handleLocator(index, "left_operand", null);
      handleLocator(index, "operand", null);
      handleLocator(index, "right_operand", null);
    }
  }, [expression.id]);

  const handleUpdate = e => {
    const targetName = e.target.name;
    const targetValue = e.target.value;
    handleLocator(index, targetName, targetValue);
  };

  return (
    <>
      <Grid sx={{mx: 1}} item xs={3}>
        <TextField
          fullWidth
          label={t("key")}
          name={"left_operand"}
          value={expression.locator.left_operand}
          onChange={handleUpdate}
        />
      </Grid>
      <Grid sx={{mx: 1}} item xs={2}>
        <TextField
          label={t("blockSettings.selectOperand")}
          name="operand"
          select
          value={expression.locator.operand}
          onChange={handleUpdate}
          sx={{px: 0, width: "100%"}}
        >
          {operands.map(operand => (
            <MenuItem key={operand.label} value={operand.value}>
              {t(operand.label)}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid sx={{mx: 1}} item xs={2}>
        <TextField
          fullWidth
          label={t("value")}
          name={"right_operand"}
          value={expression.locator.right_operand}
          onChange={handleUpdate}
        />
      </Grid>
    </>
  );
};
