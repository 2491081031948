import {
  Box,
  Card,
  Divider,
  Grid,
  TextField,
  ToggleButton,
  Typography,
} from "@mui/material";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {StyledToggleButtonGroup} from "src/components/styled-toggle-button-group";
import {LoadingButton} from "@mui/lab";
import {superParticipantRequestService} from "src/services/super/project/participant-request";
import {ParticipantRequestMeta as ParticipantRequestMetaObj} from "src/entities/project/participant-request";
import {useParams} from "react-router-dom";

export const ParticipantRequestMeta = () => {
  const {t} = useTranslation();
  const {id} = useParams();
  const [participantRequestMeta, setParticipantRequestMeta] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    superParticipantRequestService.getParticipantRequestMeta(id).then(response => {
      const meta = new ParticipantRequestMetaObj(response);
      setParticipantRequestMeta(meta);
    });
  }, []);

  if (participantRequestMeta === null) {
    return null;
  }

  const handleTopic = event => {
    participantRequestMeta.setTopic(event.target.value);
    setParticipantRequestMeta(participantRequestMeta.clone());
  };

  const handleDuration = event => {
    participantRequestMeta.setDuration(event.target.value);
    setParticipantRequestMeta(participantRequestMeta.clone());
  };

  const handleIsDefault = (event, newStatus) => {
    participantRequestMeta.setIsDefault(newStatus);
    setParticipantRequestMeta(participantRequestMeta.clone());
  };

  const handleSetParticipantRequestMeta = () => {
    setIsLoading(true);
    superParticipantRequestService
      .setParticipantRequestMeta(id, participantRequestMeta)
      .then(response => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xl={4} lg={4} md={4} xs={12}>
          <Card sx={{width: "100%", alignItems: "center", px: 4, py: 2, my: 2}}>
            <Typography variant="h6" component="div" sx={{py: 1}}>
              Project Topic
            </Typography>
            <TextField
              fullWidth
              label={"Project Topic"}
              variant="outlined"
              value={participantRequestMeta.topic}
              onChange={handleTopic}
            />
          </Card>
        </Grid>
        <Grid item xl={4} lg={4} md={4} xs={12}>
          <Card sx={{width: "100%", alignItems: "center", px: 4, py: 2, my: 2}}>
            <Typography variant="h6" component="div" sx={{py: 1}}>
              {t("sendParticipant.durationMinute")}
            </Typography>
            <TextField
              fullWidth
              label={t("sendParticipant.durationMinute")}
              variant="outlined"
              value={participantRequestMeta.duration}
              onChange={handleDuration}
              type={"number"}
            />
          </Card>
        </Grid>
        <Grid item xl={4} lg={4} md={4} xs={12}>
          <Card sx={{width: "100%", alignItems: "center", px: 4, py: 2, my: 2}}>
            <Typography variant="h6" component="div" sx={{py: 1}}>
              Default Project
            </Typography>
            <StyledToggleButtonGroup
              size="small"
              value={participantRequestMeta.is_default}
              onChange={handleIsDefault}
              aria-label="project status alignment"
              exclusive={true}
            >
              <ToggleButton value={true} aria-label="true" sx={{px: 2}}>
                <Typography fontSize={13} fontWeight="bold">
                  True
                </Typography>
              </ToggleButton>

              <Divider flexItem orientation="vertical" sx={{mx: 0.5}} />

              <ToggleButton value={false} aria-label="false" sx={{px: 2}}>
                <Typography fontSize={13} fontWeight="bold">
                  False
                </Typography>
              </ToggleButton>
            </StyledToggleButtonGroup>
          </Card>
        </Grid>
      </Grid>
      <Box sx={{display: "flex", justifyContent: "flex-end"}}>
        <LoadingButton
          loading={isLoading}
          disabled={!participantRequestMeta.isValid()}
          onClick={handleSetParticipantRequestMeta}
          sx={{mt: 2}}
          size="large"
          type="submit"
          variant="contained"
        >
          Set Participant Request Meta
        </LoadingButton>
      </Box>
    </>
  );
};
