import {Grid} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import {useTranslation} from "react-i18next";
import {Expression} from "src/components/expression";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutline from "@mui/icons-material/RemoveCircleOutline";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";

export const RewardCondition = props => {
  const {t} = useTranslation();
  const {
    condition,
    questions,
    handleCondition,
    handleLocator,
    handleAddExpression,
    handleDeleteExpression,
  } = props;

  const logicTypes = [
    {
      label: t("question"),
      value: "question",
    },
    {
      label: t("embeddedData"),
      value: "embedded_data",
    },
    {
      label: t("duration"),
      value: "duration",
    },
  ];

  const handleLogicType = (expressionIndex, event) => {
    const value = event.target.value;
    const name = event.target.name;
    handleCondition(expressionIndex, name, value);
  };

  return (
    <>
      {condition.expressions.length === 0 ? (
        <Button onClick={() => handleAddExpression(0)} variant="contained">
          {t("addRewardCondition")}
        </Button>
      ) : (
        condition.expressions.map((expression, index) => (
          <Grid container sx={{mb: 2}}>
            <Grid item xs={2}>
              <TextField
                fullWidth
                label={t("logicType")}
                name="expression_type"
                select
                value={expression.expression_type}
                onChange={e => handleLogicType(index, e)}
              >
                {logicTypes.map((item, index) => (
                  <MenuItem key={index} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Expression
              index={index}
              expression={expression}
              questions={questions}
              handleCondition={handleCondition}
              handleLocator={handleLocator}
            />
            <Grid
              item
              xs={2}
              sx={{display: "flex", alignItems: "center", justifyContent: "center"}}
            >
              {index === condition.expressions.length - 1 && (
                <IconButton
                  onClick={() => handleAddExpression(index)}
                  disabled={index === 2}
                  color="error"
                >
                  <AddCircleOutlineIcon />
                </IconButton>
              )}
              <IconButton onClick={() => handleDeleteExpression(index)} color="error">
                <RemoveCircleOutline />
              </IconButton>
            </Grid>
          </Grid>
        ))
      )}
    </>
  );
};
