import axiosInstance from "src/services/axiosInstance";

class TeamService {
  async listTeamMemberProjects(teamId, teamMemberId, page) {
    return await axiosInstance
      .get(`/super/teams/${teamId}/members/${teamMemberId}/projects`, {
        params: {page: page},
      })
      .then(response => {
        return response?.data;
      })
      .catch(err => {
        console.log("err");
      });
  }
}

export const superTeamService = new TeamService();
