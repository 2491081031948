import {
  ACCESS_TOKEN_EXPIRATION_KEY,
  ACCESS_TOKEN_KEY,
  CSRF_TOKEN_KEY,
} from "src/services/axiosInstance";
import axiosInstance from "src/services/axiosInstance";

function getCookie(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== "") {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === name + "=") {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

const signIn = async (email, password) => {
  const data = {email: email, password: password};

  return await axiosInstance
    .post("/auth/login", data)
    .then(response => {
      const responseData = response?.data;

      localStorage.setItem(ACCESS_TOKEN_KEY, responseData?.access);
      localStorage.setItem(ACCESS_TOKEN_EXPIRATION_KEY, responseData?.access_expiration);

      return responseData;
    })
    .catch(err => {
      throw err;
    });
};

const register = async data => {
  return await axiosInstance.post("/auth/register", data);
};

const resetPassword = async data => {
  return await axiosInstance.post("/auth/reset-password", data);
};

const setPassword = async data => {
  return await axiosInstance.post("/auth/set-password", data);
};

const signOut = async () => {
  await localStorage.removeItem(ACCESS_TOKEN_KEY);
  await localStorage.removeItem(ACCESS_TOKEN_EXPIRATION_KEY);
  return;
};

const me = async () => {
  const headers = {};

  const token = localStorage.getItem(ACCESS_TOKEN_KEY);
  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  }
  return await axiosInstance
    .get("/auth/me", headers)
    .then(response => {
      return response.data;
    })
    .catch(err => {
      console.error("[Auth Api]: ", err);
      return false;
    });
};

const getCsrfToken = async () => {
  return await axiosInstance
    .post("/auth/csrf", {})
    .then(response => {
      const csrftoken = getCookie("csrftoken"); // Assuming getCookie function is defined

      localStorage.setItem(CSRF_TOKEN_KEY, csrftoken);

      return null;
    })
    .catch(err => false);
};

const activateUser = async payload => {
  return await axiosInstance
    .post(`/teams/members/activate`, payload)
    .then(flow => {
      return flow?.data;
    })
    .catch(err => {
      console.log("err");
    });
};

getCsrfToken();

export const newauthService = {
  me,
  signIn,
  register,
  resetPassword,
  setPassword,
  signOut,
  activateUser,
};
