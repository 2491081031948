import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import {LoadingButton} from "@mui/lab";
import React, {useEffect, useState} from "react";
import {superRewardService} from "src/services/super/project/reward";
import {useAuth} from "src/hooks/use-auth";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import {superProjectService} from "src/services/super/project/project";
import {useTranslation} from "react-i18next";

export const AssignRewardProject = props => {
  const {t, i18n} = useTranslation();
  const [phones, setPhones] = useState(null);
  const [isAddRewardLoading, setIsAddRewardLoading] = useState(false);
  const [selectedProject, setSelectedProject] = useState("");
  const [projects, setProjects] = useState([]);
  const [hasMoreProjects, setHasMoreProjects] = useState(true);
  const [loadingProjects, setLoadingProjects] = useState(false);
  const [page, setPage] = useState(0);
  const auth = useAuth();

  const fetchRewardProjects = _page => {
    setLoadingProjects(true);
    superProjectService.listRewardProject(_page + 1).then(response => {
      setProjects(prevSurveys => [...prevSurveys, ...response.results]);
      if (response.next === null) {
        setHasMoreProjects(false);
      }
      setPage(_page);
      setLoadingProjects(false);
    });
  };

  useEffect(() => {
    fetchRewardProjects(page);
  }, []);

  const handleAddReward = () => {
    setIsAddRewardLoading(true);
    const data = {
      phones: phones.split("\n"),
      project_id: selectedProject,
    };
    superRewardService.addReward(data).then(response => {
      auth.showAlert("success", JSON.stringify(response));
      setIsAddRewardLoading(false);
    });
  };

  const handleSelectSurvey = event => {
    setSelectedProject(event.target.value);
  };

  const handleScroll = event => {
    const bottom =
      event.target.scrollHeight - event.target.scrollTop === event.target.clientHeight;
    if (bottom && hasMoreProjects && !loadingProjects) {
      fetchRewardProjects(page);
    }
  };

  return (
    <>
      <FormControl fullWidth>
        <InputLabel>Select Reward</InputLabel>
        <Select
          labelId="infinite-scroll-select-label"
          value={selectedProject}
          onChange={handleSelectSurvey}
          MenuProps={{
            PaperProps: {
              onScroll: handleScroll,
              style: {maxHeight: 300},
            },
          }}
          fullWidth
        >
          {projects.map((project, index) => (
            <MenuItem key={index} value={project.id}>
              {project.title}
            </MenuItem>
          ))}
          {loadingProjects && (
            <MenuItem disabled>
              <CircularProgress size={24} />
            </MenuItem>
          )}
        </Select>
      </FormControl>
      <Stack sx={{mt: 2}} spacing={12} direction={"row"}>
        <TextField
          fullWidth
          label="Phones"
          placeholder={"05XXXXXXXXX"}
          value={phones}
          rows={4}
          multiline
          onChange={e => setPhones(e.target.value)}
        />
      </Stack>
      <LoadingButton
        fullWidth
        loading={isAddRewardLoading}
        disabled={!(phones && selectedProject)}
        onClick={handleAddReward}
        sx={{mt: 3}}
        size="large"
        type="submit"
        variant="contained"
      >
        Add Reward
      </LoadingButton>
    </>
  );
};
