import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {SplashScreen} from "src/components/splash-screen";
import {teamService} from "src/new_services/team";
import Box from "@mui/material/Box";
import {Container} from "@mui/system";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import SvgIcon from "@mui/material/SvgIcon";
import PlusIcon from "@untitled-ui/icons-react/build/esm/Plus";
import {Dialog} from "@mui/material";
import TextField from "@mui/material/TextField";
import {SlideUpTransition} from "src/components/slide-up-transition";
import TeamMembersTable from "src/pages/admin/teams/team-members-table";
import {useRouter} from "src/hooks/use-router";
import MenuItem from "@mui/material/MenuItem";

export const teamMemberRole = {
  Member: "member",
  Staff: "staff",
  Admin: "admin",
  Super: "super",
};

export const teamMemberRoleMap = [
  {
    label: "Member",
    value: teamMemberRole.Member,
  },
  {
    label: "Staff",
    value: teamMemberRole.Staff,
  },
  {
    label: "Admin",
    value: teamMemberRole.Admin,
  },
  {
    label: "Super",
    value: teamMemberRole.Super,
  },
];

const TeamDetails = () => {
  const {id} = useParams();
  const [openInviteTeamMember, setOpenInviteTeamMember] = useState(false);
  const [invitingTeamMember, setInvitingTeamMember] = useState(false);
  const [emails, setEmails] = useState(null);
  const [role, setRole] = useState(null);
  const [team, setTeam] = useState(null);
  const [page, setPage] = useState(0);
  const router = useRouter();

  useEffect(() => {
    teamService.fetchTeamDetails(id).then(team => {
      setTeam(team);
    });
  }, []);

  if (team === null) {
    return <SplashScreen />;
  }

  const handlePageChange = () => {};

  const handleInviteTeamMember = () => {
    const data = {
      emails: emails.split("\n"),
      role: role ?? teamMemberRole.Member,
    };
    setInvitingTeamMember(true);
    teamService.inviteTeamMember(id, data).then(_ => router.refresh());
  };

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 1,
      }}
    >
      <Container maxWidth="xl">
        <Stack spacing={4}>
          <Stack spacing={4}>
            <Stack
              alignItems="flex-start"
              direction={{
                xs: "column",
                md: "row",
              }}
              justifyContent="space-between"
              spacing={4}
            >
              <Stack spacing={1}>
                <Typography variant="h4">{team.name}</Typography>
              </Stack>
              <Stack spacing={1}>
                <Button
                  onClick={() => setOpenInviteTeamMember(true)}
                  startIcon={
                    <SvgIcon>
                      <PlusIcon />
                    </SvgIcon>
                  }
                  variant="contained"
                >
                  Invite Member
                </Button>
                <Dialog
                  fullScreen
                  open={openInviteTeamMember}
                  onClose={() => setOpenInviteTeamMember(false)}
                  TransitionComponent={SlideUpTransition}
                >
                  <Box
                    sx={{
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "center",
                      flex: "1 1 auto",
                    }}
                  >
                    <Container
                      maxWidth="sm"
                      sx={{
                        py: {
                          xs: "60px",
                          md: "120px",
                        },
                      }}
                    >
                      <Container maxWidth="lg">
                        <Typography align="center" variant={"h4"}>
                          Invite Team Member
                        </Typography>
                        <Box maxWidth="sm">
                          <Stack sx={{mt: 2}} spacing={2} direction={"row"}>
                            <TextField
                              fullWidth
                              label="Emails"
                              value={emails}
                              rows={4}
                              multiline
                              onChange={e => setEmails(e.target.value)}
                            />
                          </Stack>
                          <Stack sx={{mt: 3}} spacing={2} direction={"row"}>
                            <TextField
                              fullWidth
                              label={"Role"}
                              name="contentType"
                              select
                              value={role ?? teamMemberRole.Member}
                              onChange={e => setRole(e.target.value)}
                            >
                              {teamMemberRoleMap.map(teamMemberRole => (
                                <MenuItem
                                  key={teamMemberRole.value}
                                  value={teamMemberRole.value}
                                >
                                  {teamMemberRole.label}
                                </MenuItem>
                              ))}
                            </TextField>
                          </Stack>
                          <Button
                            fullWidth
                            disabled={invitingTeamMember}
                            onClick={handleInviteTeamMember}
                            sx={{mt: 3}}
                            size="large"
                            type="submit"
                            variant="contained"
                          >
                            Invite
                          </Button>
                          <Button
                            sx={{mt: 2}}
                            fullWidth
                            onClick={() => setOpenInviteTeamMember(false)}
                          >
                            Cancel
                          </Button>
                        </Box>
                      </Container>
                    </Container>
                  </Box>
                </Dialog>
              </Stack>
            </Stack>
          </Stack>
          <TeamMembersTable
            page={page}
            paginatedTeamMembers={team.users}
            handlePageChange={handlePageChange}
          />
        </Stack>
      </Container>
    </Box>
  );
};

export default TeamDetails;
