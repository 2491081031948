import axiosInstance from "src/services/axiosInstance";

class RewardService {
  async listRewardRequests(page, status) {
    return await axiosInstance
      .get(`/super/reward-requests`, {
        params: {page: page, status: status},
      })
      .then(response => {
        return response?.data;
      })
      .catch(err => {
        console.log("err");
      });
  }

  async getRewardRequestDetails(id) {
    return await axiosInstance
      .get(`/super/reward-requests/${id}`)
      .then(response => {
        return response?.data;
      })
      .catch(err => {
        console.log("err");
      });
  }

  async approveRewardRequest(id, payload) {
    return await axiosInstance
      .patch(`/super/reward-requests/${id}`, payload)
      .then(response => {
        return response?.data;
      })
      .catch(err => {
        console.log("err");
      });
  }

  async addReward(payload) {
    return await axiosInstance
      .post(`/super/reward`, payload)
      .then(response => {
        return response?.data;
      })
      .catch(err => {
        console.log("err");
      });
  }
}

export const superRewardService = new RewardService();
