import useMediaQuery from "@mui/material/useMediaQuery";
import {TopNav} from "src/layouts/dashboard/top-nav";
import styled from "@emotion/styled";
import {SideNav} from "src/layouts/dashboard/side-nav";
import {withAuthGuard} from "src/hocs/with-auth-guard";
import HelpBubble from "src/pages/main/contact/help-buble";

const SIDE_NAV_WIDTH = 280;

const DashboardLayoutRoot = styled.div(({theme}) => ({
  display: "flex",
  flex: "1 1 auto",
  maxWidth: "100%",
  [theme.breakpoints.up("lg")]: {
    paddingLeft: SIDE_NAV_WIDTH,
  },
}));

const DashboardLayoutContainer = styled.div(_ => ({
  display: "flex",
  flex: "1 1 auto",
  flexDirection: "column",
  width: "100%",
}));

const DashboardContainer = styled.div(_ => ({
  display: "flex",
  flex: "1 1 auto",
  flexDirection: "column",
  width: "100%",

  height: "100vh",
  overflow: "auto",
}));

export const DashboardLayout = withAuthGuard(props => {
  const {children} = props;
  const lgUp = useMediaQuery(theme => theme.breakpoints.up("lg"));

  return (
    <DashboardContainer>
      <TopNav />
      <SideNav />
      <DashboardLayoutRoot>
        <DashboardLayoutContainer>{children}</DashboardLayoutContainer>
      </DashboardLayoutRoot>
      <HelpBubble />
    </DashboardContainer>
  );
});
