import React from "react";
import {Box, FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import {useTranslation} from "react-i18next";

const MotionSettings = ({motionSettings, setMotionSettings}) => {
  const {t} = useTranslation();

  return (
    <Box>
      <Box mb={2}>
        <FormControl fullWidth>
          <InputLabel id="lfe-logo-placement-label">
            {t("designTab.motionStyles.animationType")}
          </InputLabel>
          <Select
            labelId="lfe-logo-placement-label"
            id="lfe-logo-placement"
            value={motionSettings?.pageTransition}
            label="Placement"
            onChange={e =>
              setMotionSettings({...motionSettings, pageTransition: e.target.value})
            }
          >
            <MenuItem value="slide">{t("designTab.motionStyles.slide")}</MenuItem>
            <MenuItem value="fade">{t("designTab.motionStyles.fade")}</MenuItem>
            <MenuItem value="grow">{t("designTab.motionStyles.grow")}</MenuItem>
            <MenuItem value="zoom">{t("designTab.motionStyles.zoom")}</MenuItem>
            <MenuItem value="collapse">{t("designTab.motionStyles.collapse")}</MenuItem>
          </Select>
        </FormControl>
      </Box>

      {motionSettings?.pageTransition === "slide" && (
        <Box mb={2}>
          <FormControl fullWidth>
            <InputLabel id="lfe-logo-placement-label">
              {t("designTab.motionStyles.slideAnimationDirection")}
            </InputLabel>
            <Select
              labelId="lfe-logo-placement-label"
              id="lfe-logo-placement"
              value={motionSettings?.translationDirection}
              label="Placement"
              onChange={e =>
                setMotionSettings({
                  ...motionSettings,
                  translationDirection: e.target.value,
                })
              }
            >
              <MenuItem value="right">{t("designTab.similars.right")}</MenuItem>
              <MenuItem value="left">{t("designTab.similars.left")}</MenuItem>
              <MenuItem value="top">{t("designTab.similars.top")}</MenuItem>
              <MenuItem value="bottom">{t("designTab.similars.bottom")}</MenuItem>
            </Select>
          </FormControl>
        </Box>
      )}
    </Box>
  );
};

export default MotionSettings;
