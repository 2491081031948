import axiosInstance from "src/services/axiosInstance";

class ProjectService {
  async createDefaultProjectSurvey(title) {
    const data = {
      title: title,
    };

    return await axiosInstance
      .post(`/projects`, data)
      .then(survey => {
        return survey?.data?.id;
      })
      .catch(err => {
        console.error("Error: ", err);
        throw Error();
      });
  }

  async createFromExcel(payload) {
    return await axiosInstance
      .post(`/projects/excel`, payload)
      .then(survey => {
        return survey?.data;
      })
      .catch(err => {
        console.error("Error: ", err);
      });
  }

  async deleteProjectSurvey(id) {
    return await axiosInstance
      .delete(`/projects/${id}`)
      .then(survey => {
        // return survey?.data?.results;
        return survey;
      })
      .catch(err => {
        console.error("Error: ", err);
      });
  }

  async listProjects(page, filters = {}) {
    return await axiosInstance
      .get(`/projects`, {
        params: {
          page: page,
          ...filters,
        },
      })
      .then(projects => {
        return projects?.data;
      })
      .catch(err => {
        console.log("err");
      });
  }

  async retrieveProjectSurvey(uuid) {
    return await axiosInstance
      .get(`/projects/${uuid}`)
      .then(survey => {
        // return survey?.data?.results;
        return survey?.data;
      })
      .catch(err => {
        console.error("Error: ", err);
      });
  }

  async updateProject(uuid, payload) {
    return await axiosInstance
      .patch(`/projects/${uuid}`, payload)
      .then(survey => {
        return survey?.data;
      })
      .catch(err => {
        console.error("Error: ", err);
      });
  }

  async updateProjectSurvey(uuid, payload) {
    return await axiosInstance
      .patch(`/projects/${uuid}/survey`, payload)
      .then(survey => {
        return survey?.data;
      })
      .catch(err => {
        console.error("Error: ", err);
      });
  }

  async publishProject(projectId) {
    return await axiosInstance
      .post(`/projects/${projectId}/publish`)
      .then(projects => {
        return projects?.data;
      })
      .catch(err => {
        throw Error();
      });
  }

  async listSurveyQuestions(surveyId, element) {
    return await axiosInstance
      .get(`/projects/${surveyId}/preview${element ? `?element=${element}` : ""}`)
      .then(block => {
        return block?.data;
      })
      .catch(err => {
        console.error("Error: ", err);
      });
  }

  async copyPorjectSurvey(uuid) {
    return await axiosInstance
      .post(`/projects/${uuid}/copy`)
      .then(survey => {
        // return survey?.data?.results;
        return survey;
      })
      .catch(err => {
        console.error("Error: ", err);
      });
  }
}

export const projectService = new ProjectService();
