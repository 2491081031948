import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import Button from "@mui/material/Button";
import SvgIcon from "@mui/material/SvgIcon";
import PlusIcon from "@untitled-ui/icons-react/build/esm/Plus";
import {Dialog, TextField} from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import {SlideUpTransition} from "src/components/slide-up-transition";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import {useRouter} from "src/hooks/use-router";
import {paths} from "src/pages/paths";
import {projectService} from "src/services/project/project";

export const CreateSurveyButton = () => {
  const {t, i18n} = useTranslation();
  const [open, setOpen] = useState(false);
  const [surveyName, setSurveyName] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const router = useRouter();

  const handleCreate = () => {
    setIsSubmitting(true);
    projectService.createDefaultProjectSurvey(surveyName).then(uuid => {
      router.push(paths.surveyBuilder.edit.replace(":uuid", uuid));
      setIsSubmitting(false);
    });
  };

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        startIcon={
          <SvgIcon>
            <PlusIcon />
          </SvgIcon>
        }
        variant="contained"
        sx={{direction: i18n.language === "ar" ? "rtl" : "ltr"}}
      >
        {t("createSurveyButtonLabel")}
      </Button>
      <Dialog
        fullScreen
        open={open}
        onClose={() => setOpen(false)}
        TransitionComponent={SlideUpTransition}
      >
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
            flex: "1 1 auto",
          }}
        >
          <Container
            maxWidth="sm"
            sx={{
              py: {
                xs: "60px",
                md: "120px",
              },
              direction: i18n.language === "ar" ? "rtl" : "ltr",
            }}
          >
            <Typography align="center" variant="h4">
              {t("createSurveyDialogTitle")}
            </Typography>
            <Box maxWidth="sm">
              <TextField
                sx={{mt: 3}}
                fullWidth
                label={t("nameInputLabel")}
                value={surveyName}
                onChange={e => setSurveyName(e.target.value)}
                onKeyDown={e => {
                  if (e.key === "Enter" && !isSubmitting && surveyName !== "") {
                    handleCreate();
                  }
                }}
              />
              <Button
                fullWidth
                disabled={isSubmitting || surveyName === ""}
                onClick={handleCreate}
                sx={{mt: 3}}
                type="submit"
                variant="contained"
              >
                {isSubmitting ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  t("createButtonLabel")
                )}
              </Button>
              <Button fullWidth sx={{mt: 2}} onClick={() => setOpen(false)}>
                {t("cancelButtonLabel")}
              </Button>
            </Box>
          </Container>
        </Box>
      </Dialog>
    </>
  );
};
