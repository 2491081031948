export class EmbeddedDataLocator {
  constructor(props) {
    this.left_operand = props.left_operand;
    this.operand = props.operand;
    this.right_operand = props.right_operand;
  }

  set(name, value) {
    Object.assign(this, {[name]: value});
  }

  isValid() {
    return true;
  }
}
