import "./App.css";

import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";

import {useRoutes} from "react-router-dom";
import {routes} from "src/routes";

import "src/locales/i18n";
import {AuthConsumer, AuthProvider} from "src/contexts/auth-context";
import {SplashScreen} from "src/components/splash-screen";
import {palette, ThemeProvider} from "@mui/system";
import {CssBaseline} from "@mui/material";
import {createTheme} from "@mui/material/styles";
import {createBaseOptions} from "src/theme/base";
import {galataColor} from "src/theme/colors";
import {createLightOptions} from "src/theme/light";
import GeneralAlert from "./components/general-alert";

function App() {
  const element = useRoutes(routes);
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <AuthProvider>
        <AuthConsumer>
          {auth => {
            const theme = createTheme(createBaseOptions(), createLightOptions());

            return (
              <ThemeProvider theme={theme}>
                <CssBaseline />
                <>{element}</>

                <GeneralAlert
                  open={auth.alert.open}
                  onClose={auth.closeAlert}
                  type={auth.alert.type}
                  message={auth.alert.message}
                />
              </ThemeProvider>
            );
          }}
        </AuthConsumer>
      </AuthProvider>
    </LocalizationProvider>
  );
}

export default App;
