export class ProjectAssignmentBatch {
  constructor(projectAssignmentBatch) {
    this.batchId = projectAssignmentBatch.batch_id;
    this.topic = projectAssignmentBatch.topic;
    this.status = projectAssignmentBatch.status;
    this.reward = projectAssignmentBatch.reward;
    this.duration = projectAssignmentBatch.duration;
    this.assignedParticipantCount = projectAssignmentBatch.assigned_participant_count;
  }

  incrementAssignedParticipantCount(count) {
    this.assignedParticipantCount = this.assignedParticipantCount + count;
  }
}
