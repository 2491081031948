import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Card from "@mui/material/Card";
import React from "react";
import TablePagination from "@mui/material/TablePagination";

const TeamMembersTable = props => {
  const {page, paginatedTeamMembers, handlePageChange} = props;

  const handleClick = (event, slug) => {
    // router.push(paths.dashboard.teams.details.replace(':slug', slug));
  };

  return (
    <Card elevation={0}>
      <Table sx={{minWidth: 1150}}>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Phone</TableCell>
            <TableCell>Role</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {paginatedTeamMembers.map(teamMember => {
            return (
              <TableRow key={teamMember.email}>
                <TableCell
                  onClick={event => handleClick(event, teamMember)}
                  sx={{cursor: "pointer", color: "primary.main", fontWeight: "bold"}}
                >
                  {teamMember.name}
                </TableCell>
                <TableCell>{teamMember.email}</TableCell>
                <TableCell>{teamMember.phone}</TableCell>
                <TableCell>{teamMember.role}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={paginatedTeamMembers.count}
        onPageChange={handlePageChange}
        onRowsPerPageChange={() => {}}
        page={page}
        rowsPerPage={10}
        // rowsPerPageOptions={[5, 10, 25]}
        rowsPerPageOptions={[10]}
      />
    </Card>
  );
};

export default TeamMembersTable;
