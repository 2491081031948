import {Stack} from "@mui/system";
import {SideNavItem} from "src/layouts/dashboard/side-nav/side-nav-item";

const renderItems = ({depth = 0, items, pathname, onClick}) =>
  items.reduce(
    (acc, item) => reduceChildRoutes({acc, depth, item, pathname, onClick}),
    [],
  );

const reduceChildRoutes = ({acc, depth, item, pathname, onClick}) => {
  const checkPath = !!(item.path && pathname);
  const partialMatch = checkPath ? pathname.includes(item.path) : false;
  const exactMatch = checkPath ? pathname.startsWith(item.path) : false;

  if (item.items) {
    acc.push(
      <SideNavItem
        active={partialMatch}
        depth={depth}
        disabled={item.disabled}
        icon={item.icon}
        key={item.title}
        label={item.label}
        open={false}
        title={item.title}
      >
        <Stack
          component="ul"
          spacing={0.5}
          sx={{
            listStyle: "none",
            m: 0,
            p: 0,
            pl: 2, // Add padding for subcategories
          }}
        >
          {renderItems({depth: depth + 1, items: item.items, pathname, onClick})}
        </Stack>
      </SideNavItem>,
    );
  } else {
    acc.push(
      <SideNavItem
        active={exactMatch}
        depth={depth}
        disabled={item.disabled}
        external={item.external}
        icon={item.icon}
        key={item.title}
        label={item.label}
        path={item.path}
        title={item.title}
        onClick={onClick}
      />,
    );
  }

  return acc;
};

export const SideNavSection = props => {
  const {items, pathname, onClick} = props;

  return (
    <Stack
      component="ul"
      spacing={2.5}
      sx={{
        listStyle: "none",
        m: 0,
        p: 0,
      }}
    >
      <br />
      <br />

      {renderItems({items, pathname, onClick})}
    </Stack>
  );
};
