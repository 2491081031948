import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

const operands = [
  {
    label: "isSelected",
    value: "is_selected",
  },
  {
    label: "isNotSelected",
    value: "is_not_selected",
  },
];

export function multipleChoiceExpressionText(expression, question) {
  const option = question.options.find(option => option.id === expression.right_operand);
  return `${question.label} ${option.text} ${expression.operand}`;
}

export const MultipleChoiceLocator = props => {
  const {index, question, locator, handleLocator} = props;
  const {t} = useTranslation();
  const [selectedOption, setSelectedOption] = useState("");

  useEffect(() => {
    if (locator.left_operand !== question.reference_id) {
      handleLocator(index, "right_operand", null);
      handleLocator(index, "operand", null);
    } else {
      const option = question.options.find(
        option => option.reference_id === locator.right_operand,
      );
      setSelectedOption(option);
      handleLocator(index, "right_operand", option);
      const operand = locator.operand;
      handleLocator(index, "operand", operand);
    }
  }, [question.id]);

  const handleOperand = e => {
    const value = e.target.value;
    handleLocator(index, "operand", value);
  };

  const handleSelectedOption = e => {
    const value = e.target.value;
    setSelectedOption(value);
    handleLocator(index, "right_operand", value);
  };

  return (
    <>
      <Grid sx={{pl: 1}} item xs={3}>
        <TextField
          label={t("blockSettings.selectAnOption")}
          name="option"
          select
          value={selectedOption}
          onChange={handleSelectedOption}
          sx={{px: 0, width: "100%"}}
        >
          {question.options.map(option => (
            <MenuItem key={option.id} value={option}>
              {option.text}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid sx={{pl: 1}} item xs={2}>
        <TextField
          label={t("blockSettings.selectAQuestion")}
          name="question"
          select
          value={locator.operand}
          onChange={handleOperand}
          sx={{px: 0, width: "100%"}}
        >
          {operands.map((operand, index) => (
            <MenuItem key={index} value={operand.value}>
              {t(operand.label)}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
    </>
  );
};
