import React from "react";
import {Box, MenuItem, Select, InputLabel, FormControl, TextField} from "@mui/material";
import {useTranslation} from "react-i18next";

const GeneralSettings = ({generalSettings, setGeneralSettings}) => {
  const {t} = useTranslation();

  return (
    <Box>
      {/* Next Button Text */}
      <Box className="lfe-settings-component-container" mb={2}>
        <FormControl fullWidth>
          <TextField
            id="lfe-next-button-input"
            label={t("designTab.generalStyles.nextButtonText")}
            variant="outlined"
            InputLabelProps={{shrink: true}} // Etiketin input içinde başlamasını ve girdi olduğunda yukarı kaymasını sağlar.
            defaultValue={generalSettings?.nextButton}
            onChange={e =>
              setGeneralSettings({...generalSettings, nextButton: e.target.value})
            }
          />
        </FormControl>
      </Box>

      {/* Previous Button Text */}
      <Box className="lfe-settings-component-container" mb={2}>
        <FormControl fullWidth variant="outlined">
          <TextField
            id="lfe-previous-button-input"
            label={t("designTab.generalStyles.previousButtonText")}
            variant="outlined"
            InputLabelProps={{shrink: true}}
            defaultValue={generalSettings?.previousButton}
            onChange={e =>
              setGeneralSettings({...generalSettings, previousButton: e.target.value})
            }
          />
        </FormControl>
      </Box>

      <Box mt={2}>
        <FormControl fullWidth>
          <InputLabel id="lfe-logo-placement-label">
            {t("designTab.generalStyles.progressBar")}
          </InputLabel>
          <Select
            labelId="lfe-logo-placement-label"
            id="lfe-logo-placement"
            value={generalSettings?.progressBar}
            label={t("designTab.generalStyles.progressBar")}
            onChange={e =>
              setGeneralSettings({...generalSettings, progressBar: e.target.value})
            }
          >
            <MenuItem value="None">{t("designTab.generalStyles.none")}</MenuItem>
            <MenuItem value="WithText">{t("designTab.generalStyles.withText")}</MenuItem>
            <MenuItem value="WithoutText">
              {t("designTab.generalStyles.withoutText")}
            </MenuItem>
          </Select>
        </FormControl>
      </Box>

      <Box mt={2}>
        <FormControl fullWidth>
          <InputLabel id="vertical-placement-label">
            {t("designTab.generalStyles.progressBarPlacement")}
          </InputLabel>
          <Select
            labelId="vertical-placement-label"
            id="vertical-placement-select"
            value={generalSettings?.progressBarPlacement}
            label="Vertical Placement"
            onChange={e =>
              setGeneralSettings({
                ...generalSettings,
                progressBarPlacement: e.target.value,
              })
            }
          >
            <MenuItem value="Left">{t("designTab.similars.left")}</MenuItem>
            <MenuItem value="Center">{t("designTab.similars.center")}</MenuItem>
            <MenuItem value="Right">{t("designTab.similars.right")}</MenuItem>
          </Select>
        </FormControl>
      </Box>

      <Box mt={2}>
        <FormControl fullWidth>
          <InputLabel id="vertical-placement-label">
            {t("designTab.generalStyles.progressBarVerticalPlacement")}
          </InputLabel>
          <Select
            labelId="vertical-placement-label"
            id="vertical-placement-select"
            value={generalSettings?.progressBarVerticalPlacement}
            label="Vertical Placement"
            onChange={e =>
              setGeneralSettings({
                ...generalSettings,
                progressBarVerticalPlacement: e.target.value,
              })
            }
          >
            <MenuItem value="Top">{t("designTab.similars.top")}</MenuItem>
            <MenuItem value="Bottom">{t("designTab.similars.bottom")}</MenuItem>
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
};

export default GeneralSettings;
